// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography,NativeSelect } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import "../Button.css"
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import moment from 'moment';

const PaymentMethod = () => {
  document.title = "Payments | Maevisory";

  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }


  const [isLoading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState('')
  const [rows, setRows] = useState(null)

  const [userType, setUserType]= useState("");




  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
 
  

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',
      
    }, 

  }
 
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };



  function applyRefresh() {
    setRefresh(!refresh)
  }

  const columns = [
    {
      field: 'userName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Therapist',
      cellClassName: 'gridCell',
      flex: 0.3,
      renderCell: cellValues => (
        <Button
          size='small'
          variant='text'
          color='info'
          onClick={(event) => {
            openTakePayment(event,cellValues.row.id )
          }}
        >{`${cellValues.row.userName}`}</Button>
      )
    },
   
    {
      field: 'totalPayment',
      headerClassName: 'super-app-theme--header',
      headerName: 'Payment Amount',
      flex: 0.32,
     
    },
    {
      field: 'paymentMode',
      headerClassName: 'super-app-theme--header',
      headerName: 'Mode Of Payment',
      flex: 0.5,
     
    },
    
    {
      field: 'paymentDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'Payment Date',
      flex: 0.3,
      renderCell: cellValues => (
        moment(cellValues.value).format('MM-DD-YYYY')
    )
    },
 
      {
        field: 'status',
        headerClassName: 'super-app-theme--header',
        headerName: 'Status',
        flex: 0.3
      }
      /*,
    
    {
      field: 'status',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.1,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {
                     
                    inactive: true,
                      id: cell.row.id
                    }

                    axios
                      .post(`${process.env.REACT_APP_API_URL}/LeaveRequest/addLeaveRequest`, postData, {
                        headers
                      })
                      .then(response => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch(error => {
                        console.error('There was an error!', error)
                      })
                  }
                },
                {
                  label: 'No'
                }
              ]
            })
          }}
        ></DeleteIcon>
      )
    }
   */
  ]


  const handleChange = event => {
    const newValue = event.target.value
    setSelectedValue(newValue)
    applyRefresh(newValue)
    console.log("Selected value my kya ha : " ,  newValue)
  }
 

  const onFilterSearch = event => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/Payment/getPaymentRequests`, { headers })
      .then(response => {
        setLoading(false)
        setRows(response)
      })
      .catch(error => {})

  }


  useEffect(()=>{
    setUserType(localStorage.getItem("User_Type"));
  },[])
  const [totalCompletePayment, setTotalCompletePayment] = useState(0);
  const [totalPendingPayment, setTotalPendingPayment] = useState(0);
  console.log('toatal pennding',totalPendingPayment)
  console.log('toatal compelet',totalCompletePayment)

  useEffect(() => {
    setLoading(true)   
 
    axios
      .get(`${process.env.REACT_APP_API_URL}/Payment/getPaymentRequests`, { headers })
      .then(response => {
        setLoading(false)
        const data = response;
        if (Array.isArray(data)) {
          setRows(data);

          // Filter the data to include only items where the status is "complete"
          const totalComplete = data
          .filter(payment => payment.status === 'Completed')
          .reduce((sum, payment) => {
            console.log('Complete Payment:', payment.totalPayment); // Log each complete payment
            return sum + (payment.totalPayment || 0); // Ensure totalPayment is a number
          }, 0);

        const totalPending = data
          .filter(payment => payment.status === 'Pending')
          .reduce((sum, payment) => {
            console.log('Pending Payment:', payment.totalPayment); // Log each pending payment
            return sum + (payment.totalPayment || 0); // Ensure totalPayment is a number
          }, 0);
           setTotalCompletePayment(totalComplete);
        setTotalPendingPayment(totalPending);
        } else {
          console.error('Data is not an array:', data);
        }
      })
      .catch(error => {})
    

  }, [refresh,selectedValue])
 
 
 
  const openTakePayment = (event, id = 0) => {
    navigate('/TakePayment', { state: { paymentId: id } })
  }

  return (
    <div className="page-content">
    <Container >
      <Grid container spacing={1}>

       <Grid item xs={3} sm={3} xl={3}>
        <Typography style={{color:'black',fontSize:'18px',marginTop:'6px'}}>
        Payment Request
      </Typography>
        </Grid>

        <Grid item xs={3} sm={3} xl={3}>
        <Typography style={{color:'black',fontSize:'15px',marginTop:'7px'}}>
        Total Paid: <b>{totalCompletePayment}</b>
      </Typography>
        </Grid>
        <Grid item xs={3} sm={3} xl={3}>
        <Typography style={{color:'black',fontSize:'15px',marginTop:'7px'}}>
        Total Pending: <b>{totalPendingPayment}</b>
      </Typography>
        </Grid>
     
        


        <Grid  item xs={3} sm={3} xl={3}>

        {userType=="Therapist"?(<></>):(
        <Button  className="Button" onClick={openTakePayment} variant='contained' style={{fontSize:'14px',fontWeight:'bold',
          float:"right"}}>
        <AddOutlinedIcon
        fontSize='small'
        style={{
          color: 'white',
          paddingRight: '4px',
          paddingBottom: '2px',
          
        }}
      ></AddOutlinedIcon>
        Request Payment
        </Button>)}
        </Grid>


       

       <Grid item xs={12} sm={12} xl={12}>
       <Box mb={0} style={{ marginTop: 0 }}>


      {isLoading ? (
    <div style={{ margin:'5rem', textAlign:'center' }}>
       <CircularProgress /> 
        </div>
         ) : (
        <div mb={0} style={{ height: 400, width: '100%' }}>
          {rows === null || rows.length === 0 ? ("No Record Found") : (
            <DataGrid
              rows={rows}
              classes={gridClasses}
              rowHeight={20}
              columns={columns}
              getRowSpacing={getRowSpacing}
              //checkboxSelection
              fullWidth
              sx={gridRowStyle}
              pagination
              autoPageSize
            />
          )}
        </div>
        )}


      </Box>
       </Grid>
      </Grid>
    </Container>
  </div>
      
     
  )
}

export default PaymentMethod
