import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const alert = useAlert();

  const [formData, setFormData] = useState({
    name: '', email: '', addressLine1: '', city: '', postalCode: '', country: '', phone: ''
  });
  const token = localStorage.getItem('Token')
  const [refresh, setRefresh] = useState(false)

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }

  
  function applyRefresh() {
    setRefresh(!refresh)
  }


  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    addressLine1: Yup.string().required('Address Line 1 is required'),
    city: Yup.string().required('City is required'),
    postalCode: Yup.string().required('Postal Code is required'),
    country: Yup.string().required('Country is required'),
    phone: Yup.string().required('Phone is required')
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setLoading(true);

    try {
      await validationSchema.validate(formData, { abortEarly: false });
    } catch (validationError) {
      setError(validationError.errors[0]);
      setLoading(false);
      return;
    }

    if (!stripe || !elements) {
      setError('Stripe has not been properly initialized.');
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    setLoading(false);
    const { error, token } = await stripe.createToken(cardElement);
    console.log(token.id)
    if (error) {
      setError(error.message);
    } else {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/Stripe/add-card`, {
        email: formData.email,
        name: formData.name,
        phone: formData.phone,
        description: "New card added",
        address: {
          line1: formData.addressLine1,
          line2: '',
          city: formData.city,
          state: '',
          postalCode: formData.postalCode,
          country: formData.country
        },
        sourceToken: token.id
      }, { headers });

      if (response.status === 200) {
       
        alert.success("Card added successfully", {
          onClose: () => navigate('/PaymentMethod'),
          refresh
        
        });
        applyRefresh()
      } else {
        alert.error("Failed to add card");
      }
    }
  };

  return (
    <div style={styles.container}>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.formGroup}>
          <div style={styles.inputGroup}>
            <label htmlFor="name" style={styles.formLabel}> Name </label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} style={styles.formControl} />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="email" style={styles.formLabel}> Email </label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} style={styles.formControl} />
          </div>
        </div>
        <div style={styles.formGroup}>
          <div style={styles.inputGroup}>
            <label htmlFor="addressLine1" style={styles.formLabel}> Address Line 1 </label>
            <input type="text" id="addressLine1" name="addressLine1" value={formData.addressLine1} onChange={handleChange} style={styles.formControl} />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="city" style={styles.formLabel}> City </label>
            <input type="text" id="city" name="city" value={formData.city} onChange={handleChange} style={styles.formControl} /> </div>
        </div> <div style={styles.formGroup}>
          <div style={styles.inputGroup}>
            <label htmlFor="postalCode" style={styles.formLabel}> Postal Code </label>
            <input type="text" id="postalCode" name="postalCode" value={formData.postalCode} onChange={handleChange} style={styles.formControl} />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="country" style={styles.formLabel}> Country </label>
            <input type="text" id="country" name="country" value={formData.country} onChange={handleChange} style={styles.formControl} />
          </div>
        </div>
        <div style={styles.formGroup}>
          <div style={styles.inputGroup}>
            <label htmlFor="phone" style={styles.formLabel}> Phone </label>
            <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} style={styles.formControl} /> 
            </div> 
            </div>
             <div style={styles.formGroup}> 
              <div style={styles.inputGroup}>
                 <label htmlFor="card-element" style={styles.formLabel}> Card Details </label>
                  <CardElement style={styles.formControl} /> 
                  </div> 
                  </div>
                   <button type="submit" style={styles.btn} disabled={!stripe || loading}>
                     {loading ? 'Processing...' : 'Add Card'} </button> 
                     {error && <div style={styles.error}>{error}</div>} 
                     </form> 
                     </div>);
}; 

export default PaymentForm
const styles = {
  container: 
  { display: 'flex',
     justifyContent: 'center',
      marginTop: '1rem',
      padding:"10px"
     },
   form: { 
    maxWidth: '900px', 
    width: '100%', padding: '20px', 
    border: '1px solid #377562', borderRadius: '5px',
   },
    formGroup: {
       display: 'flex',
        justifyContent: 'space-between',
         marginBottom: '20px',
         },
          inputGroup: { 
            flex: 1, 
            marginRight: '10px',
           },

           formLabel: {
             fontWeight: 'bold',
             marginBottom: '5px',
             display: 'block',
             },
              formControl:
               {
                 width: '100%',
                  padding: '10px',
                   fontSize: '16px',
                    border: '1px solid #ccc',
                     borderRadius: '5px',
                      boxSizing: 'border-box',
                     },
                  btn:
                   { display: 'block', 
                    width: '100%',
                     padding: '10px',
                     backgroundColor: '#007bff',
                     color: '#fff', 
                     border: 'none',
                      borderRadius: '5px',
                       cursor: 'pointer',
                        marginTop: '20px',
                       },
  error: 
  { 
    marginTop: '10px',
     color: '#dc3545',
      fontSize: '14px',
     },
};
