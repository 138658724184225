// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import "../Button.css"
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from "@mui/material/Modal";
import TherapistRegistration from "../TherapistRegistration";

import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import VideoCallIcon from '@mui/icons-material/VideoCall'
import Draggable from 'react-draggable'
import { Resizable } from 're-resizable'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
const TherapistDashboard = () => {
  document.title = "TherapistDashboard | Maevisory";

  const [isLoading, setLoading] = useState(false)
  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const roomName = localStorage.getItem('roomName')

  console.log('roomname', roomName)
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }

  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate();

  const columns = [
    {
      field: 'locationType',
      headerClassName: 'super-app-theme--header',
      headerName: 'Location Type',
      cellClassName: 'gridCell',
      flex: 0.3,
      renderCell: cellValues => (
        <Button
          size='small'
          variant='text'
          color='info'
          onClick={(event) => {
            openUserRegistration(event, cellValues.row.id)
          }}
        >{`${cellValues.row.locationType}`}</Button>
      )
    },
    // {
    //   field: 'email',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: 'Email Address',
    //   cellClassName: 'gridCell',
    //   flex: 0.5,
    //   renderCell: cellValues => (
    //     <Button
    //       size='small'
    //       variant='text'
    //       color='info'
    //       onClick={(event) => {
    //         openUserRegistration(event,cellValues.row.id )
    //       }}
    //     >{`${cellValues.row.email}`}</Button>
    //   )
    // },
    {
      field: 'website',
      headerClassName: 'super-app-theme--header',
      headerName: 'Website',
      flex: 0.5
    },
    {
      field: 'startDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'Start Date',
      flex: 0.3
    },
    {
      field: 'endDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'End Date',
      flex: 0.3
    },
    {
      field: 'inActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.1,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            const postData = {
              id: cell.row.id,
              inActive: true,

            }
            axios
              .post(`${process.env.REACT_APP_API_URL}/Locations/AddLocations`, postData, {
                headers
              })
              .then(response => {
                setRefresh(!refresh)

              })
              .catch(error => {
                console.error('There was an error!', error)
              })
            // confirmAlert({
            //   message: 'Do you want to delete the record.',
            //   closeOnEscape: true,
            //   closeOnClickOutside: true,
            //   overlayClassName: 'overlay-custom-class-name',
            //   buttons: [
            //     {
            //       label: 'Yes',
            //       onClick: () => {
            //         const postData = {
            //           id: cell.row.id,
            //           inActive: true,

            //         }

            //         axios
            //           .post(`${process.env.REACT_APP_API_URL}/Locations/AddLocations`, postData, {
            //             headers
            //           })
            //           .then(response => {
            //             setRefresh(!refresh)

            //           })
            //           .catch(error => {
            //             console.error('There was an error!', error)
            //           })
            //       }
            //     },
            //     {
            //       label: 'No'
            //     }
            //   ]
            // })
          }}
        ></DeleteIcon>
      )
    }

  ]

  const [rows, setRows] = useState(null)
  useEffect(() => {
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Locations/getLocations`, { headers })
      .then(response => {
        setLoading(false)
        setRows(response)
      })
      .catch(error => { })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
  const columnstherapist = [

    {
      field: 'therapistId',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      flex: 0.2
    },
    {
      field: 'userName',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Name',
      flex: 0.3
    },


    {
      field: 'inActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.1,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            const postData = {

              inActive: true,
              id: cell.row.id
            }
            axios
              .post(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/supervisorTherapist`, postData, {
                headers
              })
              .then(response => {
                setRefresh(!refresh)
                // setOpenNote(false)
                // console.log('response.data: ', response.data)
              })
              .catch(error => {
                console.error('There was an error!', error)
              })
            // confirmAlert({
            //   message: 'Do you want to delete the record.',
            //   closeOnEscape: true,
            //   closeOnClickOutside: true,
            //   overlayClassName: 'overlay-custom-class-name',
            //   buttons: [
            //     {
            //       label: 'Yes',
            //       onClick: () => {
            //         const postData = {

            //           inActive: true,
            //           id: cell.row.id
            //         }

            //         axios
            //           .post(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/supervisorTherapist`, postData, {
            //             headers
            //           })
            //           .then(response => {
            //             setRefresh(!refresh)

            //           })
            //           .catch(error => {
            //             console.error('There was an error!', error)
            //           })
            //       }
            //     },
            //     {
            //       label: 'No'
            //     }
            //   ]
            // })
          }}
        ></DeleteIcon>
      )
    }
  ]
  const columnsSupervisionGroups = [

    {
      field: 'therapistId',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      flex: 0.2
    },
    {
      field: 'userName',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Name',
      flex: 0.3
    },



  ]
  const Appointments = [

    {
      field: 'id',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      flex: 0.2
    },
    {
      field: 'fullName',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Name',
      flex: 0.3
    },
    {
      field: 'appointmentType',
      headerClassName: 'super-app-theme--header',
      headerName: 'Appointment Type',
      flex: 0.3
    },



  ]
  const ReviewHours = [

    {
      field: 'id',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      flex: 0.2
    },
    {
      field: 'fullName',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Name',
      flex: 0.3
    },
    {
      field: 'appointmentType',
      headerClassName: 'super-app-theme--header',
      headerName: 'Appointment Type',
      flex: 0.3
    },



  ]
  const columnsBilling = [

    {
      field: 'id',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      flex: 0.2
    },
    {
      field: 'userName',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Name',
      flex: 0.3
    },
    {
      field: 'paymentMode',
      headerClassName: 'super-app-theme--header',
      headerName: 'Appointment Type',
      flex: 0.3
    },



  ]
  const columnsMessages = [

    {
      field: 'id',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      flex: 0.2
    },
    {
      field: 'fullName',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Name',
      flex: 0.3
    },
    {
      field: 'appointmentType',
      headerClassName: 'super-app-theme--header',
      headerName: 'Appointment Type',
      flex: 0.3
    },



  ]
  const columnsIncidentReports = [

    {
      field: 'id',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      flex: 0.2
    },
    {
      field: 'supervisorFirstName',
      headerClassName: 'super-app-theme--header',
      headerName: 'First Name',
      flex: 0.3
    },
    {
      field: 'supervisorLastName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Last Name',
      flex: 0.3
    },
    {
      field: 'witnesses',
      headerClassName: 'super-app-theme--header',
      headerName: 'Witnesses',
      flex: 0.3
    },




  ]
  const columnsSupervisionAgendas = [

    {
      field: 'id',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      flex: 0.2
    },
    {
      field: 'supervisorFirstName',
      headerClassName: 'super-app-theme--header',
      headerName: 'First Name',
      flex: 0.3
    },
    {
      field: 'supervisorLastName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Last Name',
      flex: 0.3
    },





  ]



  const openUserRegistration = (event, id = 0) => {
    navigate('/SitePageRegistration', { state: { uid: id } })
  }
  const _userId = localStorage.getItem('userid')
  const username = localStorage.getItem('UserName')
  const [openn, setOpenn] = useState(false)
  const [doc, setDoc] = useState()
  console.log("rooooooooooooom", roomName)
  const handleopen = () => {
    setOpenn(true)
    setDoc(`https://dttmeetingroom.devforhealth.com/dttroomtestappshami.html?roomID=${roomName}`)

  }
  const handleClose = () => {
    setOpenn(false)


  }
  const [openprofile, setOpenprofile] = React.useState(false);
  const handleCloseprofile = () => setOpenprofile(false);
  const handleOpenprofile = () => {
    setOpenprofile(true);
  };
  const [opentherapist, setOpentherapist] = React.useState(false);
  const [rowstherapist, setRowstherapist] = useState(null)

  const handleClosetherapist = () => setOpentherapist(false);
  const handleOpentherapist = () => {
    setOpentherapist(true);
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/supervisorTherapistList?supervisorId=${_userId}`, { headers })
      .then(response => {
        setLoading(false)
        setRowstherapist(response)
      })
      .catch(error => { })

  };

  const [openSupervisionGroups, setSupervisionGroups] = React.useState(false);
  const [rowSupervisionGroups, setRowsSupervisionGroups] = useState(null)

  const handleCloseSupervisionGroups = () => setSupervisionGroups(false);
  const handleOpenSupervisionGroups = () => {
    setSupervisionGroups(true);
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/SupervisorGroups/supervisorGroupList?supervisorId=${_userId}`, { headers })
      .then(response => {
        setLoading(false)
        setRowsSupervisionGroups(response)
      })
      .catch(error => { })

  };
  const [openAppointments, setAppointments] = React.useState(false);
  const [rowAppointments, setRowsAppointments] = useState(null)

  const handleCloseAppointments = () => setAppointments(false);
  const handleOpenAppointments = () => {
    setAppointments(true);
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Appointment/getAppointmentByUserId?userId=${_userId}`, { headers })
      .then(response => {
        setLoading(false)
        setRowsAppointments(response)
      })
      .catch(error => { })

  };
  function handleCancelReviewHours() {
    navigate("/TrackHours");
  }

  function handleCancelBILLING() {
    navigate("/PaymentMethod");
  }
  const [openReviewHours, setReviewHours] = React.useState(false);
  const [rowReviewHours, setRowsReviewHours] = useState(null)

  const handleCloseReviewHours = () => setReviewHours(false);
  const handleOpenReviewHours = () => {
    setReviewHours(true);
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Dashboard/getTrackHoursUserId?userId=${_userId}`, { headers })
      .then(response => {
        setLoading(false)
        setRowsReviewHours(response)
      })
      .catch(error => { })

  };

  const [openBilling, setBilling] = React.useState(false);
  const [rowBilling, setRowsBilling] = useState(null)

  const handleCloseBilling = () => setBilling(false);
  const handleOpenBilling = () => {
    setBilling(true);
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Payment/getPaymentRequestById?userId=${_userId}`, { headers })
      .then(response => {
        setLoading(false)
        setRowsBilling(response)
      })
      .catch(error => { })

  };

  const [openMessages, setMessages] = React.useState(false);
  const [rowMessages, setRowsMessages] = useState(null)
  function handleCancelMessages() {
    navigate("/Message");
  }

  const handleCloseMessages = () => setMessages(false);
  const handleOpenMessages = () => {
    setMessages(true);
    setLoading(true)

    axios
      .get(`${process.env.REACT_APP_API_URL}/Dashboard/getMessagesByUserId?supervisorId=${_userId}`, { headers })
      .then(response => {
        setLoading(false)
        setRowsMessages(response)
      })
      .catch(error => { })

  };
  function handleCancelIncidentReports() {
    navigate("/IncidentReporting");
  }
  const [openIncidentReports, setIncidentReports] = React.useState(false);
  const [rowIncidentReports, setRowsIncidentReports] = useState(null)

  const handleCloseIncidentReports = () => setIncidentReports(false);
  const handleOpenIncidentReports = () => {
    setIncidentReports(true);
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/IncidentReport/getIncidentReportByUserId?userId=${_userId}`, { headers })
      .then(response => {
        setLoading(false)
        setRowsIncidentReports(response)
      })
      .catch(error => { })

  };
  function handleCancelSupervisionAgendas() {
    navigate("/Agendas");
  }
  const [openSupervisionAgendas, setSupervisionAgendas] = React.useState(false);
  const [rowSupervisionAgendas, setRowsSupervisionAgendas] = useState(null)

  const handleCloseSupervisionAgendas = () => setSupervisionAgendas(false);
  const handleOpenSupervisionAgendas = () => {
    setSupervisionAgendas(true);
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Dashboard/getUserNotesBySuepervisorId?suepervisorId=${_userId}`, { headers })
      .then(response => {
        setLoading(false)
        setRowsSupervisionAgendas(response)
      })
      .catch(error => { })

  };


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    height: 500,
    padding:'10px',
    bgcolor: "background.paper",
    border: "2px solid #356F60",
    boxShadow: 24,
    // overflow: "auto",
    // overflows: "hide",
    // zIndex: "10",
    customHeader: {
      backgroundColor: 'black', // Apply your desired background color
      color: 'white', // Apply the desired text color
      fontWeight: 'bold',
    },
  };
  const [state, setState] = useState({ width: 320, height: 200 })

  const [minimized, setMinimized] = useState(false)
  const styleDetailsPdf = {
    // width: '80%',
    // height: '85%',
    width: state.width,
    height: state.height,
    bgcolor: '#F0FFFF',
    position: 'absolute',
    backgroundColor: 'white',
    top: '94px',
    // left: '267px',
    // bottom: '100px',
    right: '100px',
    margin: 0,
    marginTop: 0,
    paddingBottom: '10px',
    padding: '0px',
    // overflow: 'auto',
    zIndex: '10',
    // tabIndex: "-1",
  }
  const handleResizeStop = (e, direction, ref, d) => {
    var oldState = state

    setState({
      width: oldState.width + d.width,
      height: oldState.height + d.height,
    })
  }

  const resizableStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    outline: 'black',
    border: '1px solid black',
    padding: '16px',
    position: 'absolute',
  }
  const handleMinimize = () => {
    setMinimized(true)
  }
  const style1 = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 400,
    bgcolor: 'white',
    border: '2px solid #EA7772',
    boxShadow: 24,
    // overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',


  }
  return (
    <div className="page-content">
      <Container >
        {minimized ? (
          ''
        ) : (
          <Modal
            open={openn}
            closeOnEscape={false}
            closeOnClickOutside={false}
            onClose={() => { }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box>
              <Grid xs={8}>
                <Box style={{ cursor: 'move' }}>
                  <Draggable
                    bounds={{ left: -800, top: -100, right: 400, bottom: 500 }}
                  >
                    <Resizable
                      defaultSize={{ width: '80%', height: 600 }}
                      style={styleDetailsPdf}
                      onResizeStop={handleResizeStop}
                    >
                      <Box sx={{ resizableStyle }}>
                        {/* <div style = {{backgroundColor : 'Aqua'}} className="modal-title-bar"> */}

                        <Grid container style={{ backgroundColor: '#356f60' }} >

                          <Grid item xs={9}></Grid>
                          <Grid item xs={3}>
                            <Button sx={{ mb: 1 }} className="Button" onClick={handleClose} variant='contained'
                              style={{
                                fontSize: '14px', fontWeight: 'bold', float: 'right', marginTop: '10px', marginRight: '25px',
                                marginBottom: '5px',
                                width: '80px',
                                height: '35px',
                              }}>
                              <CancelOutlinedIcon
                                fontSize='small'
                                style={{
                                  color: 'red',
                                  paddingRight: '4px',
                                  paddingBottom: '2px'
                                }}
                              ></CancelOutlinedIcon>
                              Close
                            </Button>

                            <Button sx={{ mb: 1 }} className="Button" onClick={handleMinimize} variant='contained'
                              style={{
                                fontSize: '14px', fontWeight: 'bold', float: 'right', marginTop: '10px',
                                marginBottom: '5px',
                                marginRight: '13px',
                                width: '100px',
                                height: '35px',
                              }}>
                              <CloseFullscreenIcon
                                fontSize='small'
                                style={{
                                  color: 'red',
                                  paddingRight: '4px',
                                  paddingBottom: '2px'
                                }}
                              ></CloseFullscreenIcon>
                              Minimize
                            </Button>



                          </Grid>
                        </Grid>

                        {/* </div> */}

                        {openn ? (
                          <Grid
                            container
                            columnSpacing={1}
                            alignContent="center"
                            justifyContent="center"
                          >
                            <div
                              id="iframe_container"
                              style={{
                                position: 'absolute',
                                top: '3%',
                                // top: '15%',
                                bottom: '0%',
                                left: '0%',
                                right: '0%',
                                marginTop: '10px',
                                // marginBottom:"10px",
                              }}
                            >
                              <iframe
                                name="ifr"
                                // scrolling="yes"
                                fullWidth
                                style={{
                                  position: 'absolute',
                                  height: '100%',
                                  width: '100%',
                                }}
                                src={`${doc}`}
                                frameBorder="0"
                                allow="microphone; camera"
                                allowFullScreen
                              />
                            </div>
                          </Grid>
                        ) : null}
                      </Box>
                    </Resizable>
                  </Draggable>
                </Box>
              </Grid>
            </Box>
          </Modal>
        )}

        {/* Profile Modal */}
        <Modal
          open={openprofile}
          onClose={handleCloseprofile}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style1} >
            <Headroom >
              <Grid
                container
                style={{ backgroundColor: '#356F60', position: 'sticky' }}
              >
                <Grid item xs={12} sm={12} xl={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'left',
                      justifyContent: 'space-between',
                      height: '2rem'
                    }}
                  >
                    <DialogTitle
                      style={{
                        flex: 1,
                        // textAlign: 'left',
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          // textAlign: 'left',
                          marginTop: '-10px',
                          color: '#fff',
                          textDecoration: 'underline',
                          fontSize: '1rem'

                        }}
                      >
                        Therapist Registration

                      </h1>
                    </DialogTitle>
                    <CancelOutlinedIcon
                      onClick={handleCloseprofile}
                      fontSize="large"
                      color="red"
                      style={{
                        color: 'red',
                        paddingRight: '5px',
                        cursor: 'pointer',
                        float: 'right'
                        // marginLeft: 'auto',
                        // marginRight: '1.5rem'
                      }}
                    ></CancelOutlinedIcon>
                  </div>
                </Grid>
              </Grid>
            </Headroom>
            <Grid item xs={12} sm={12} xl={12} >
              <Card bordered={false} style={{
                overflow: 'auto',
                /* Other styles */
                scrollbarWidth: 'thin', // For Firefox
                scrollbarColor: '#356F60 transparent', // thumb color and track color
                '&::-webkit-scrollbar': {
                  width: 8, /* Width of vertical scrollbar */
                  height: 8, /* Height of horizontal scrollbar */
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#356F60', /* Color of the thumb */
                  borderRadius: 10,
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'transparent', /* Color of the track */
                },
                /* Adjust the width for the horizontal scrollbar */
                '&::-webkit-scrollbar-thumb:horizontal': {
                  width: 4, /* Width of horizontal scrollbar */
                },
                // zIndex: "10",

                height: '80vh'
              }} >
                <TherapistRegistration callingFrom={'dashboard'} uid={_userId} handleCloseprofile={handleCloseprofile}></TherapistRegistration>
              </Card>

            </Grid>


          </Box>
        </Modal>
        {/* therapist Modal */}
        <Modal
          open={opentherapist}
          onClose={handleClosetherapist}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Headroom>
              <Grid
                container
                style={{ backgroundColor: '#356F60', position: 'sticky' }}
              >
                <Grid item xs={12} sm={12} xl={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'left',
                      justifyContent: 'space-between',
                      height: '2rem'
                    }}
                  >
                    <DialogTitle
                      style={{
                        flex: 1,
                        // textAlign: 'left',
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          marginTop: '-10px',
                          color: '#fff',
                          textDecoration: 'underline',
                          fontSize: '1rem'

                        }}
                      >
                        Therapist List

                      </h1>
                    </DialogTitle>
                    <CancelOutlinedIcon
                      onClick={handleClosetherapist}
                      fontSize="large"
                      color="red"
                      style={{
                        color: 'red',
                        paddingRight: '5px',
                        cursor: 'pointer',
                        float: 'right'
                        // marginLeft: 'auto',
                        // marginRight: '1.5rem'
                      }}
                    ></CancelOutlinedIcon>
                  </div>
                </Grid>
              </Grid>


            </Headroom>
            <Grid container style={{ marginTop: '10px' }}>
              <Grid item xs={12} sm={12} xl={12}>
                {isLoading ? (
                  <div style={{ margin: '5rem', textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div mb={0} style={{ height: 400, width: '100%' }}>
                    {rowstherapist === null || rowstherapist.length === 0 ? "Record not Found" : (
                      <DataGrid
                        rows={rowstherapist}
                        classes={gridClasses}
                        rowHeight={25}
                        columns={columnstherapist}
                        getRowSpacing={getRowSpacing}
                        //checkboxSelection
                        fullWidth
                        sx={gridRowStyle}
                        pagination
                        autoPageSize
                      />
                    )}
                  </div>
                )}

              </Grid>
            </Grid>





          </Box>
        </Modal>
        {/* SupervisionGroups Modal */}
        <Modal
          open={openSupervisionGroups}
          onClose={handleCloseSupervisionGroups}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Headroom>
              <Grid
                container
                style={{ backgroundColor: '#356F60', position: 'sticky' }}
              >
                <Grid item xs={12} sm={12} xl={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'left',
                      justifyContent: 'space-between',
                      height: '2rem'
                    }}
                  >
                    <DialogTitle
                      style={{
                        flex: 1,
                        // textAlign: 'left',
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          marginTop: '-10px',
                          color: '#fff',
                          textDecoration: 'underline',
                          fontSize: '1rem'

                        }}
                      >
                        Supervision Groups List

                      </h1>
                    </DialogTitle>
                    <CancelOutlinedIcon
                      onClick={handleCloseSupervisionGroups}
                      fontSize="large"
                      color="red"
                      style={{
                        color: 'red',
                        paddingRight: '5px',
                        cursor: 'pointer',
                        float: 'right'
                        // marginLeft: 'auto',
                        // marginRight: '1.5rem'
                      }}
                    ></CancelOutlinedIcon>
                  </div>
                </Grid>
              </Grid>


            </Headroom>
            <Grid container style={{ marginTop: '10px' }}>
              <Grid item xs={12} sm={12} xl={12}>
                {isLoading ? (
                  <div style={{ margin: '5rem', textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div mb={0} style={{ height: 400, width: '100%' }}>
                    {rowSupervisionGroups === null || rowSupervisionGroups.length === 0 ? "Record not Found" : (
                      <DataGrid
                        rows={rowSupervisionGroups}
                        classes={gridClasses}
                        rowHeight={25}
                        columns={columnsSupervisionGroups}
                        getRowSpacing={getRowSpacing}
                        //checkboxSelection
                        fullWidth
                        sx={gridRowStyle}
                        pagination
                        autoPageSize
                      />
                    )}
                  </div>
                )}

              </Grid>
            </Grid>





          </Box>
        </Modal>
        {/* Appointments Modal */}
        <Modal
          open={openAppointments}
          onClose={handleCloseAppointments}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Headroom>
              <Grid
                container
                style={{ backgroundColor: '#356F60', position: 'sticky' }}
              >
                <Grid item xs={12} sm={12} xl={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'left',
                      justifyContent: 'space-between',
                      height: '2rem'
                    }}
                  >
                    <DialogTitle
                      style={{
                        flex: 1,
                        // textAlign: 'left',
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          marginTop: '-10px',
                          color: '#fff',
                          textDecoration: 'underline',
                          fontSize: '1rem'

                        }}
                      >
                        Appointments List

                      </h1>
                    </DialogTitle>
                    <CancelOutlinedIcon
                      onClick={handleCloseAppointments}
                      fontSize="large"
                      color="red"
                      style={{
                        color: 'red',
                        paddingRight: '5px',
                        cursor: 'pointer',
                        float: 'right'
                        // marginLeft: 'auto',
                        // marginRight: '1.5rem'
                      }}
                    ></CancelOutlinedIcon>
                  </div>
                </Grid>
              </Grid>


            </Headroom>
            <Grid container style={{ marginTop: '10px' }}>
              <Grid item xs={12} sm={12} xl={12}>
                {isLoading ? (
                  <div style={{ margin: '5rem', textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div mb={0} style={{ height: 400, width: '100%' }}>
                    {rowAppointments === null || rowAppointments.length === 0 ? "Record not Found" : (
                      <DataGrid
                        rows={rowAppointments}
                        classes={gridClasses}
                        rowHeight={25}
                        columns={Appointments}
                        getRowSpacing={getRowSpacing}
                        //checkboxSelection
                        fullWidth
                        sx={gridRowStyle}
                        pagination
                        autoPageSize
                      />
                    )}
                  </div>
                )}

              </Grid>
            </Grid>





          </Box>
        </Modal>
        {/*  ReviewHours Modal */}
        <Modal
          open={openReviewHours}
          onClose={handleCloseReviewHours}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Headroom>
              <Grid
                container
                style={{ backgroundColor: '#356F60', position: 'sticky' }}
              >
                <Grid item xs={12} sm={12} xl={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'left',
                      justifyContent: 'space-between',
                      height: '2rem'
                    }}
                  >
                    <DialogTitle
                      style={{
                        flex: 1,
                        // textAlign: 'left',
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          marginTop: '-10px',
                          color: '#fff',
                          textDecoration: 'underline',
                          fontSize: '1rem'

                        }}
                      >
                        Review Hours List

                      </h1>
                    </DialogTitle>
                    <CancelOutlinedIcon
                      onClick={handleCloseReviewHours}
                      fontSize="large"
                      color="red"
                      style={{
                        color: 'red',
                        paddingRight: '5px',
                        cursor: 'pointer',
                        float: 'right'
                        // marginLeft: 'auto',
                        // marginRight: '1.5rem'
                      }}
                    ></CancelOutlinedIcon>
                  </div>
                </Grid>
              </Grid>


            </Headroom>
            <Grid container style={{ marginTop: '10px' }}>
              <Grid item xs={12} sm={12} xl={12}>
                {isLoading ? (
                  <div style={{ margin: '5rem', textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div mb={0} style={{ height: 400, width: '100%' }}>
                    {rowReviewHours === null || rowReviewHours.length === 0 ? "Record not Found" : (
                      <DataGrid
                        rows={rowReviewHours}
                        classes={gridClasses}
                        rowHeight={25}
                        columns={ReviewHours}
                        getRowSpacing={getRowSpacing}
                        //checkboxSelection
                        fullWidth
                        sx={gridRowStyle}
                        pagination
                        autoPageSize
                      />
                    )}
                  </div>
                )}

              </Grid>
            </Grid>





          </Box>
        </Modal>
        {/*  Billing Modal */}
        <Modal
          open={openBilling}
          onClose={handleCloseBilling}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Headroom>
              <Grid
                container
                style={{ backgroundColor: '#356F60', position: 'sticky' }}
              >
                <Grid item xs={12} sm={12} xl={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'left',
                      justifyContent: 'space-between',
                      height: '2rem'
                    }}
                  >
                    <DialogTitle
                      style={{
                        flex: 1,
                        // textAlign: 'left',
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          marginTop: '-10px',
                          color: '#fff',
                          textDecoration: 'underline',
                          fontSize: '1rem'

                        }}
                      >
                        Billing List

                      </h1>
                    </DialogTitle>
                    <CancelOutlinedIcon
                      onClick={handleCloseBilling}
                      fontSize="large"
                      color="red"
                      style={{
                        color: 'red',
                        paddingRight: '5px',
                        cursor: 'pointer',
                        float: 'right'
                        // marginLeft: 'auto',
                        // marginRight: '1.5rem'
                      }}
                    ></CancelOutlinedIcon>
                  </div>
                </Grid>
              </Grid>


            </Headroom>
            <Grid container style={{ marginTop: '10px' }}>
              <Grid item xs={12} sm={12} xl={12}>
                {isLoading ? (
                  <div style={{ margin: '5rem', textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div mb={0} style={{ height: 400, width: '100%' }}>
                    {rowBilling === null || rowBilling.length === 0 ? "Record not Found" : (
                      <DataGrid
                        rows={rowBilling}
                        classes={gridClasses}
                        rowHeight={25}
                        columns={columnsBilling}
                        getRowSpacing={getRowSpacing}
                        //checkboxSelection
                        fullWidth
                        sx={gridRowStyle}
                        pagination
                        autoPageSize
                      />
                    )}
                  </div>
                )}

              </Grid>
            </Grid>





          </Box>
        </Modal>
        {/*  Messages Modal */}
        <Modal
          open={openMessages}
          onClose={handleCloseMessages}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Headroom>
              <Grid
                container
                style={{ backgroundColor: '#356F60', position: 'sticky' }}
              >
                <Grid item xs={12} sm={12} xl={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'left',
                      justifyContent: 'space-between',
                      height: '2rem'
                    }}
                  >
                    <DialogTitle
                      style={{
                        flex: 1,
                        // textAlign: 'left',
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          marginTop: '-10px',
                          color: '#fff',
                          textDecoration: 'underline',
                          fontSize: '1rem'

                        }}
                      >
                        Messages List

                      </h1>
                    </DialogTitle>
                    <CancelOutlinedIcon
                      onClick={handleCloseMessages}
                      fontSize="large"
                      color="red"
                      style={{
                        color: 'red',
                        paddingRight: '5px',
                        cursor: 'pointer',
                        float: 'right'
                        // marginLeft: 'auto',
                        // marginRight: '1.5rem'
                      }}
                    ></CancelOutlinedIcon>
                  </div>
                </Grid>
              </Grid>


            </Headroom>
            <Grid container style={{ marginTop: '10px' }}>
              <Grid item xs={12} sm={12} xl={12}>
                {isLoading ? (
                  <div style={{ margin: '5rem', textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div mb={0} style={{ height: 400, width: '100%' }}>
                    {rowMessages === null || rowMessages.length === 0 ? "Record not Found" : (
                      <DataGrid
                        rows={rowMessages}
                        classes={gridClasses}
                        rowHeight={25}
                        columns={columnsMessages}
                        getRowSpacing={getRowSpacing}
                        //checkboxSelection
                        fullWidth
                        sx={gridRowStyle}
                        pagination
                        autoPageSize
                      />
                    )}
                  </div>
                )}

              </Grid>
            </Grid>





          </Box>
        </Modal>
        {/*  Incident Reports Modal */}
        <Modal
          open={openIncidentReports}
          onClose={handleCloseIncidentReports}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Headroom>
              <Grid
                container
                style={{ backgroundColor: '#356F60', position: 'sticky' }}
              >
                <Grid item xs={12} sm={12} xl={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'left',
                      justifyContent: 'space-between',
                      height: '2rem'
                    }}
                  >
                    <DialogTitle
                      style={{
                        flex: 1,
                        // textAlign: 'left',
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          marginTop: '-10px',
                          color: '#fff',
                          textDecoration: 'underline',
                          fontSize: '1rem'

                        }}
                      >
                        Incident Reports List

                      </h1>
                    </DialogTitle>
                    <CancelOutlinedIcon
                      onClick={handleCloseIncidentReports}
                      fontSize="large"
                      color="red"
                      style={{
                        color: 'red',
                        paddingRight: '5px',
                        cursor: 'pointer',
                        float: 'right'
                        // marginLeft: 'auto',
                        // marginRight: '1.5rem'
                      }}
                    ></CancelOutlinedIcon>
                  </div>
                </Grid>
              </Grid>


            </Headroom>
            <Grid container style={{ marginTop: '10px' }}>
              <Grid item xs={12} sm={12} xl={12}>
                {isLoading ? (
                  <div style={{ margin: '5rem', textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div mb={0} style={{ height: 400, width: '100%' }}>
                    {rowIncidentReports === null || rowIncidentReports.length === 0 ? "Record not Found" : (
                      <DataGrid
                        rows={rowIncidentReports}
                        classes={gridClasses}
                        rowHeight={25}
                        columns={columnsIncidentReports}
                        getRowSpacing={getRowSpacing}
                        //checkboxSelection
                        fullWidth
                        sx={gridRowStyle}
                        pagination
                        autoPageSize
                      />
                    )}
                  </div>
                )}

              </Grid>
            </Grid>





          </Box>
        </Modal>
        {/*  Incident Reports Modal */}
        <Modal
          open={openSupervisionAgendas}
          onClose={handleCloseSupervisionAgendas}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Headroom>
              <Grid
                container
                style={{ backgroundColor: '#356F60', position: 'sticky' }}
              >
                <Grid item xs={12} sm={12} xl={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'left',
                      justifyContent: 'space-between',
                      height: '2rem'
                    }}
                  >
                    <DialogTitle
                      style={{
                        flex: 1,
                        // textAlign: 'left',
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          marginTop: '-10px',
                          color: '#fff',
                          textDecoration: 'underline',
                          fontSize: '1rem'

                        }}
                      >
                        Supervision Agendas List

                      </h1>
                    </DialogTitle>
                    <CancelOutlinedIcon
                      onClick={handleCloseSupervisionAgendas}
                      fontSize="large"
                      color="red"
                      style={{
                        color: 'red',
                        paddingRight: '5px',
                        cursor: 'pointer',
                        float: 'right'
                        // marginLeft: 'auto',
                        // marginRight: '1.5rem'
                      }}
                    ></CancelOutlinedIcon>
                  </div>
                </Grid>
              </Grid>


            </Headroom>
            <Grid container style={{ marginTop: '10px' }}>
              <Grid item xs={12} sm={12} xl={12}>
                {isLoading ? (
                  <div style={{ margin: '5rem', textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div mb={0} style={{ height: 400, width: '100%' }}>
                    {rowSupervisionAgendas === null || rowSupervisionAgendas.length === 0 ? "Record not Found" : (
                      <DataGrid
                        rows={rowSupervisionAgendas}
                        classes={gridClasses}
                        rowHeight={25}
                        columns={columnsSupervisionAgendas}
                        getRowSpacing={getRowSpacing}
                        //checkboxSelection
                        fullWidth
                        sx={gridRowStyle}
                        pagination
                        autoPageSize
                      />
                    )}
                  </div>
                )}

              </Grid>
            </Grid>





          </Box>
        </Modal>


        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} xl={12}>
            <Typography style={{ fontSize: '18px', fontWeight: 'bold', color: 'black', }}>
              Therapist Dashboard
            </Typography>

          </Grid>
          <Grid container spacing={2} style={{ marginTop: '10px' }}>
            <Grid item xs={3} sm={3} md={3} lg={3}></Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Card
                sx={{
                  minWidth: 350,
                  marginBottom: "20px",
                  backgroundColor: "#377562",
                }}
              >
                <CardContent>
                  {/* <Typography
                              variant="h5"
                              color="white"
                              gutterBottom
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                               My Profile
                             
                            </Typography> */}
                  <Typography>
                    <Button
                      //   className='Button'
                      style={{
                        //   backgroundColor: "antiquewhite",
                        color: 'white',
                        fontSize: "16Px",
                        fontWeight: 'bold'
                      }}
                      onClick={handleOpenprofile}
                    >
                      {/* {totalPatients} */}
                      My Profile
                    </Button>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              {minimized ? (
                <div onClick={() => setMinimized(false)}>

                  <Button
                    className="Button"
                    variant="contained"
                    style={{
                      marginTop: '-3px',
                      marginBottom: '30px',
                      float: 'right',
                      marginLeft: 'auto',
                      width: '90px',
                      height: '30px',
                      // backgroundColor: '#FCBE13',
                      color: 'white',
                      fontSize: '14px',
                      cursor: 'pointer',
                    }}
                  >
                    Maximize
                  </Button>
                </div>
              ) : (
                <>
                  {roomName && roomName == "null" || roomName == "" ? (<>
                    {/* <Button sx={{ mb: 1 }} className="Button" disabled={true} onClick={handleopen} variant='contained' style={{ fontSize: '14px', fontWeight: 'bold', float: 'right', }}>
                      <VideoCallIcon
                        fontSize='small'
                        style={{
                          color: 'white',
                          paddingRight: '4px',
                          paddingBottom: '2px'
                        }}
                      ></VideoCallIcon>
                      Call Pick
                    </Button> */}

                  </>) : (<>

                    <Button sx={{ mb: 1 }} className="Button" onClick={handleopen} variant='contained' style={{ fontSize: '14px', fontWeight: 'bold', float: 'right', }}>
                      <VideoCallIcon
                        fontSize='small'
                        style={{
                          color: 'white',
                          paddingRight: '4px',
                          paddingBottom: '2px'
                        }}
                      ></VideoCallIcon>
                      Call Pick
                    </Button>

                  </>)}
                </>
              )}
            </Grid>
          </Grid>


          <Grid container spacing={2}>
            {/* <Grid item xs={3} sm={3} md={3} lg={3}>
                        <Card
                                sx={{
                                    height: 60,
                                    backgroundColor: "#377562",
                                }}
                                >
                                <CardContent>
                                    
                                    <Typography>
                              <Button
                         
                                style={{
                          
                                color:'white',
                                  fontSize: "16Px",
                                  fontWeight:'bold',
                                  marginTop:'-8px'
                                }}
                                onClick={handleOpentherapist}
                              >
                               
                                Therapist List
                              </Button>
                            </Typography>
                                </CardContent>
                                </Card>
                        </Grid> */}
            {/* <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Card
                                sx={{
                                    height: 60,
                                    backgroundColor: "#377562",
                                }}
                                >
                                <CardContent>
                                    
                                    <Typography>
                              <Button
                                style={{
                                
                                color:'white',
                                  fontSize: "16Px",
                                  fontWeight:'bold',
                                  marginTop:'-8px'
                                }}
                                onClick={handleOpenSupervisionGroups}
                              >
                                Supervision Groups
                              </Button>
                            </Typography>
                                </CardContent>
                                </Card>
                        </Grid> */}
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Card
                sx={{
                  height: 60,
                  backgroundColor: "#377562",
                }}
              >
                <CardContent>

                  <Typography>
                    <Button
                      style={{

                        color: 'white',
                        fontSize: "16Px",
                        fontWeight: 'bold',
                        marginTop: '-8px'
                      }}
                      onClick={handleOpenAppointments}
                    >
                      Appointments
                    </Button>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Card
                sx={{
                  height: 60,
                  backgroundColor: "#377562",
                }}
              >
                <CardContent>

                  <Typography>
                    <Button
                      style={{

                        color: 'white',
                        fontSize: "16Px",
                        fontWeight: 'bold',
                        marginTop: '-8px'
                      }}
                      // onClick={handleOpenReviewHours}
                      onClick={handleCancelReviewHours}


                    >
                      Review Hours
                    </Button>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Card
                sx={{
                  height: 60,
                  backgroundColor: "#377562",
                }}
              >
                <CardContent>

                  <Typography>
                    <Button
                      style={{

                        color: 'white',
                        fontSize: "16Px",
                        fontWeight: 'bold',
                        marginTop: '-8px'
                      }}
                    onClick={handleCancelBILLING}
                    >
                      Billing
                    </Button>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>


          </Grid>
          <Grid container spacing={2} style={{ marginTop: '20px' }}>

            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Card
                sx={{
                  height: 60,
                  backgroundColor: "#377562",
                }}
              >
                <CardContent>

                  <Typography>
                    <Button
                      style={{

                        color: 'white',
                        fontSize: "16Px",
                        fontWeight: 'bold',
                        marginTop: '-8px'
                      }}
                      // onClick={handleOpenMessages}
                      onClick={handleCancelMessages}


                    >
                      Messages
                    </Button>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Card
                sx={{
                  height: 60,
                  backgroundColor: "#377562",
                }}
              >
                <CardContent>

                  <Typography>
                    <Button
                      style={{

                        color: 'white',
                        fontSize: "16Px",
                        fontWeight: 'bold',
                        marginTop: '-8px'
                      }}
                      // onClick={handleOpenIncidentReports}
                      onClick={handleCancelIncidentReports}


                    >
                      Incident Reports
                    </Button>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Card
                sx={{
                  height: 60,
                  backgroundColor: "#377562",
                }}
              >
                <CardContent>

                  <Typography>
                    <Button
                      style={{

                        color: 'white',
                        fontSize: "16Px",
                        fontWeight: 'bold',
                        marginTop: '-8px'
                      }}
                      // onClick={handleOpenSupervisionAgendas}
                      onClick={handleCancelSupervisionAgendas}


                    >
                      {/* {totalPatients} */}
                      Supervision Agendas
                    </Button>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

          </Grid>
          {/* <Grid item xs={3} sm={3} md={3} lg={3}></Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Card
                          sx={{
                            minWidth: 350,
                            marginBottom: "20px",
                            backgroundColor: "#377562",
                          }}
                        >
                          <CardContent>
                            
                            <Typography>
                              <Button
                           
                                style={{
                               
                                color:'white',
                                  fontSize: "16Px",
                                  fontWeight:'bold'
                                }}
                                onClick={handleOpenSupervisionAgendas}
                              >
                              
                                Supervision Agendas
                              </Button>
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
              
                      <Grid item xs={3} sm={3} md={3} lg={3}></Grid> */}


        </Grid>
      </Container>
    </div>


  )
}

export default TherapistDashboard
