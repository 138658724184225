import { useState, useEffect, useRef } from "react";
import React from "react";
import ReactSelect from "react-select";
import axios from "axios";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import md5 from "md5";
import Moment from 'moment'
import InputMask from 'react-input-mask';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SignInIcon from '@mui/icons-material/PersonAdd';
import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Select
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from "react-alert";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from '@mui/material/Paper'
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Multiselect from "multiselect-react-dropdown";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from '@mui/material/Modal';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FileUploader } from "react-drag-drop-files";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import jsPDF from 'jspdf';
import 'jspdf-autotable';



const LeaveRegistration = props => {
  const fields = [
    "id",
    "practiceId",
    "supervisorId",
    // "firstName",
    // "lastName",
    "leavePurpose",
    "additionalInfo",
    "pendingApproval",
    "leaveStartDate",
    "leaveEndDate",
    "therapistsId",
    "supervisorSign",
    "superViseeSign",
    "therapistTimeStamp"
  ];
  const defValues = {
    id: 0,
    practiceId: 0,
    supervisorId: 0,
    therapistsId: 0,
    // firstName: "",
    // lastName: "",
    leavePurpose: "",
    additionalInfo: "",
    pendingApproval: true,
    leaveStartDate: "",
    leaveEndDate: "",
    supervisorSign: '',
    superViseeSign: '',
    therapistTimeStamp:""

  };

  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();


  const location = useLocation();
  const LeaveRequestid = location.state.LeaveRequestid;

  const [leaveStartDate, setleaveStartDate] = React.useState('')
  const [leaveEndDate, setleaveEndDate] = React.useState('')


  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const UserType = localStorage.getItem("User_Type")
  const UserName = localStorage.getItem("UserName")
  console.log('UserType', UserType)
  const validationSchema = Yup.object({
    leaveStartDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .required("Please select a start date"),
    leaveEndDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .required("Please select an end date"),
    leavePurpose: Yup.string().required("Leave Purpose is required"),
    therapistsId: Yup.string().required("Please Select a Therapist"),
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,

    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  const [approve, setapprove] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [CreatedDate, setCreatedDate] = useState('');
  const [TherapistTime, setTherapistTime] = useState('');
  const [therapistError, settherapistError] = useState(false);
  const [SupervisorSignError, setSupervisorSignError] = useState(false);

  const onSubmit = data => {

    let postData = data;
    postData.therapistsId = TherapistID
    postData.leaveStartDate = leaveStartDate
    postData.leaveEndDate = leaveEndDate
    if (LeaveRequestid > 0) {
      postData.createdDate = CreatedDate;
      postData.therpistAprrovalTime = TherapistTime;
    }
    if (UserType == 'Supervisor') {
      if (!data.supervisorSign) {
        setSupervisorSignError(true);
        setSubmitting(false);
        return; 
      }
    }
    if (!TherapistID) {
      settherapistError(true)
      setSubmitting(false);

      return;

    }
    setSubmitting(true);
    if (UserType == 'Admin' || UserType == 'Supervisor') {
      postData.approved = true
      postData.approvedBy = UserName
      postData.approveDateTime = Moment().format('YYYY-MM-DD HH:mm');
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/LeaveRequest/addLeaveRequest`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);

          alert.success("Record saved successfully.", {
            type: "success",
            onClose: () => {
              navigate("/LeaveRequest");
            }
          });
        }
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
  const onSubmitreject = data => {

    let postData = data;
    setSubmitting(true);
    if (UserType == 'Therapist') {
      postData.pendingApproval = true
    }
    else {
      postData.rejected = true

    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/LeaveRequest/addLeaveRequest`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);

          alert.success("Record saved successfully.", {
            type: "success",
            onClose: () => {
              navigate("/LeaveRequest");
            }
          });
        }
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
  const handleSignInSupervisor = () => {
    setValue('supervisorSign', UserName);
    setSupervisorSignError(false)
  };

  const handleSignInSupervisee = () => {
    setValue('superViseeSign', UserName);
    settherapistSignError(false)

  };
  const [therapistSignError, settherapistSignError] = useState(false);

  const OnSubmitAprroval = data => {
    let postData = data;
    setSubmitting(true);
    postData.pendingApproval = true
    postData.leaveStartDate = leaveStartDate
    postData.leaveEndDate = leaveEndDate
    postData.therapistTimeStamp = Moment().format('YYYY-MM-DD HH:mm');
    if (!data.superViseeSign) {
      settherapistSignError(true)
      setSubmitting(false);

      return;

    }
    // postData.approved = true
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/LeaveRequest/addLeaveRequest`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);

          alert.success("Record saved successfully.", {
            type: "success",
            onClose: () => {
              navigate("/LeaveRequest");
            }
          });
        }
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };

  const [Data, setData] = useState([])
  const [TherapistID, setTherapistID] = useState("");
  useEffect(() => {
    if (LeaveRequestid) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/getLeaveRequestById?id=${LeaveRequestid}`, {
          headers
        })
        .then(response => {
          // setEmergencycontantid(response.id)
          fields.forEach(field => {
            setValue(field, response[field]);
            var dateYMD = Moment(response.leaveStartDate).format('YYYY-MM-DD')
            setleaveStartDate(dateYMD)
            var dateYMD = Moment(response.leaveEndDate).format('YYYY-MM-DD')
            setleaveEndDate(dateYMD)
            setapprove(response.approved)
            setRejected(response.rejected)
            setData(response)
            setTherapistID(response.therapistsId)
            setCreatedDate(response.createdDate)
            setTherapistTime(response.therpistAprrovalTime)


          });

        })
        .catch(error => { });
    }
  }, [LeaveRequestid]);

  function handleCancel() {
    navigate("/LeaveRequest");
  }

  const style = {
    position: 'absolute',
    top: '51%',
    left: '50%',
    padding: '7px',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 500,
    bgcolor: 'white',
    border: '2px solid #377562',
    boxShadow: 24,
    overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',
    overflow: 'auto',
    /* WebKit (Chrome, Safari) */
    '&::-webkit-scrollbar': {
      width: 8, /* Width of vertical scrollbar */
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#377562',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    /* Adjust the width for the horizontal scrollbar */
    '&::-webkit-scrollbar-thumb:horizontal': {
      width: 4, /* Width of horizontal scrollbar */
    },
    /* For Firefox */
    '&*': {
      scrollbarWidth: 'thin',
    },
  }
  const [TherapistList, setTherapistList] = useState([]);

  useEffect(() => {
    if (UserType !=='Supervisor'){
      axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Therapist'}`, {
          headers
        })
        .then(response => {
          var prac = [];
          response.forEach(field => {
            prac.push({
              label: field.userName,
              value: field.id
            });
          });
          setTherapistList(prac);
        })
        .catch(error => { });
    } else{
      axios
        .get(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/GetUsersBySupervisorId?supervisorId=${Userid}&userType=${'Therapist'}`, {
          headers
        })
        .then(response => {
          var prac = [];
          response.forEach(field => {
            prac.push({
              label: field.userName,
              value: field.id
            });
          });
          setTherapistList(prac);
        })
        .catch(error => { });
    }
  }, []);

  const handleDownload = async () => {
    const doc = new jsPDF();
    
    // Set the page border with thinner thickness
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.setDrawColor(25, 42, 86); // Set border color (Dark blue)
    doc.setLineWidth(0.2); // Set border thickness to more thinner
    doc.rect(5, 5, pageWidth - 10, pageHeight - 10); // Draw border rectangle

    // Title: Bold and centered with specified color
    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 102, 204); // Set title color to RGB(0, 102, 204)
    const title = 'Leave Request Report';
    const titleWidth = doc.getTextWidth(title);
    doc.text(title, (pageWidth - titleWidth) / 2, 20);

    // General Info Section
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.setTextColor(0, 0, 0); // Black text

    // Justified multiline text function
    const justifyText = (text, x, y, maxWidth) => {
        const lines = doc.splitTextToSize(text, maxWidth);
        doc.text(lines, x, y);
    };

    const formattedStartDate = Moment(Data.leaveStartDate).format('MM-DD-YYYY');
    const formattedEndDate = Moment(Data.leaveEndDate).format('MM-DD-YYYY');

    // AutoTable for structured data
    doc.autoTable({
        startY: 40,
        theme: 'striped',
        headStyles: { fillColor: [63, 81, 181], textColor: [255, 255, 255] }, // Indigo with white text
        styles: { lineWidth: 0.2, lineColor: [0, 0, 0] },
        body: [
            [
                { content: 'Leave Start Date', styles: { halign: 'center', fontStyle: 'bold' } },
                { content: formattedStartDate, styles: { halign: 'center' } },
                { content: 'Leave End Date', styles: { halign: 'center', fontStyle: 'bold' } },
                { content: formattedEndDate, styles: { halign: 'center' } }
            ],
            [{ content: 'Purpose for Leave', colSpan: 4, styles: { fontStyle: 'bold' } }],
            [{ content: Data.leavePurpose || 'N/A', colSpan: 4, styles: { textAlign: 'justify' } }],
            [{ content: 'Additional Information', colSpan: 4, styles: { fontStyle: 'bold' } }],
            [{ content: Data.additionalInfo || 'N/A', colSpan: 4, styles: { textAlign: 'justify' } }],
            [{ content: 'Therapist ID', colSpan: 4, styles: { fontStyle: 'bold' } }],
            [{ content: Data.therapistsId || 'N/A', colSpan: 4, styles: { halign: 'left' } }]
        ],
        columnStyles: {
            0: { cellWidth: 40 },
            1: { cellWidth: 50 },
            2: { cellWidth: 40 },
            3: { cellWidth: 50 }
        }
    });

    const signatureStartY = doc.autoTable.previous.finalY + 20; // Start below the table

    // Supervisee Signature Section (Displayed First)
    const superviseeX = 14; // Align to the left
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text("Signed By Supervisee:", superviseeX, signatureStartY);
    doc.setFont('helvetica', 'normal');
    doc.text(Data.superViseeSign || 'N/A', superviseeX, signatureStartY + 10);

    const superviseeApprovalTime = Data.therapistTimeStamp
        ? Moment(Data.therapistTimeStamp).format('MM-DD-YYYY HH:mm')
        : '';
    doc.text(`Signed on: ${superviseeApprovalTime}`, superviseeX, signatureStartY + 20);

    // Supervisor Signature Section (Displayed After)
    const supervisorX = 130; // Align to the right
    doc.setFont('helvetica', 'bold');
    doc.text("Signed By Supervisor:", supervisorX, signatureStartY);
    doc.setFont('helvetica', 'normal');
    doc.text(Data.supervisorSign || 'N/A', supervisorX, signatureStartY + 10);

    const supervisorApprovalTime = Data.approveDateTime
        ? Moment(Data.approveDateTime).format('MM-DD-YYYY HH:mm')
        : '';
    doc.text(`Signed  on: ${supervisorApprovalTime}`, supervisorX, signatureStartY + 20);

    // Save the PDF with a meaningful filename
    const fileName = `leave_Request_Pdf_${Moment(new Date()).format('DD_MMM_YY')}.pdf`;
    doc.save(fileName);
};


  

  const Userid = localStorage.getItem('userid')
  useEffect(() => {
    if (UserType === "Therapist") {
      setTherapistID(Userid);

    }
  }, [UserType, Userid]);



  return (
    <React.Fragment>
      <Grid container>
        <Box sx={style}>

          <Grid container spacing={1}>

            <Grid item xs={12} sm={12} xl={12} >
              <Headroom >
                <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky' }}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            // textAlign: 'left',
                            marginTop: '-10px',
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize: '1rem'

                          }}
                        >
                          Leave Request

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={handleCancel}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float: 'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>
            </Grid>

            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={8} sm={8} xl={8}></Grid>
            <Grid item xs={3} sm={3} xl={3}>
              <Button
                className="Button"
                onClick={handleDownload}
                fullWidth
                disabled={!Data || Data.length === 0}
                variant='contained' style={{
                  fontSize: '14px', fontWeight: 'bold',
                  float: 'right'
                }}
                type="submit"
              >
                Download
                <DownloadForOfflineIcon />
              </Button>
            </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

            {approve == true ? (<>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                  Therapist
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              {UserType == 'Therapist' ? (
                <>
                  <Grid item xs={5.5} sm={5.5} xl={5.5}>
                    <Controller
                      name="therapistsId"
                      {...register("therapistsId")}
                      control={control}
                      render={({ field }) => {
                        // const selectedValue = TherapistList.find(option => option.label === UserName);
                        // console.log("UserName:", UserName);
                        // console.log("TherapistList:", TherapistList);
                        // console.log("Selected Value:", selectedValue);

                        // if (selectedValue) {
                        //   console.log("Match found:", selectedValue);
                        // } else {
                        //   console.log("No match found");
                        // }
                        return (
                          <ReactSelect
                            {...field}
                            fullWidth
                            value={TherapistList.filter(function (option) {
                              return (
                                option.value === getValues("therapistsId")
                              );
                            })}
                            // isDisabled={true}
                            onChange={e => {
                              setValue("therapistsId", e.value);
                              setTherapistID(e.value);
                            }}

                            options={TherapistList}
                          />
                        );
                      }}
                    />

                  </Grid>
                </>) :
                (
                  <>
                    <Grid item xs={5.5} sm={5.5} xl={5.5}>
                      <Controller
                        name="therapistsId"
                        // {...register("userId")}
                        control={control}
                        render={({ field }) =>
                          <ReactSelect
                            {...field}
                            fullWidth
                            value={TherapistList.filter(function (option) {
                              return (
                                option.value === getValues("therapistsId")
                              );
                            })}
                            isDisabled={true}
                            onChange={e => {
                              setValue("therapistsId", e.value);
                              setTherapistID(e.value);


                            }}
                            options={TherapistList}
                          />

                        }
                      />
                    </Grid>


                  </>)}

              <Grid item xs={5.5} sm={5.5} xl={5.5}>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Leave Start Date
                </Typography>
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Leave End Date
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name="leaveStartDate"
                  {...register("leaveStartDate")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type="date"
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                      {...field}
                      value={leaveStartDate ? leaveStartDate : ''}
                      onChange={e => {
                        const selectedDate = e.target.value;
                        setValue('leaveStartDate', selectedDate);
                        setleaveStartDate(selectedDate);

                      }}
                      fullWidth
                      error={errors.leaveStartDate}
                    />}
                />
                <p style={{ color: 'red', fontSize: '14px' }}>{errors.leaveStartDate?.message}</p>
                {/* <p style={{ color: 'red', fontSize: '14px' }}>{errors.supervisorFirstName?.message}</p> */}
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name="leaveEndDate"
                  {...register("leaveEndDate")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type="date"
                      size="small"
                      InputProps={{
                        readOnly: true
                      }}
                      {...field}
                      value={leaveEndDate || ''}
                      onChange={e => {
                        const selectedDate = e.target.value;
                        setValue('leaveEndDate', selectedDate);
                        setleaveEndDate(selectedDate);
                      }}
                      fullWidth
                      error={errors.leaveEndDate}
                    />}
                />
                <p style={{ color: 'red', fontSize: '14px' }}>{errors.leaveEndDate?.message}</p>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={11.5} sm={11.5} xl={11.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Purpose for leave
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name='leavePurpose'
                  {...register('leavePurpose')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='Enter Leave Purpose'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '70px', // set a fixed height here
                        //   overflow: 'scroll'
                      }}
                      readOnly={true}
                      {...field}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={11.5} sm={11.5} xl={11.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Additional Information
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name='additionalInfo'
                  {...register('additionalInfo')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='Enter Additional Info'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '70px', // set a fixed height here
                        //   overflow: 'scroll'
                      }}
                      readOnly={true}
                      {...field}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Supervisee Signature
                </Typography>

              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Supervisor Signature
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name="superViseeSign"
                  control={control}
                  {...register("superViseeSign")}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Supervisee Signature..."
                      {...field}
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                      variant="standard"
                      onChange={e => {
                        setValue('superViseeSign', e.target.value);
                      }}

                    />
                  }
                />
              </Grid>

              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name="superVisorSign"
                  control={control}
                  {...register("superVisorSign")}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Supervisor Signature..."
                      {...field}
                      InputProps={{
                        readOnly: true
                      }}
                      fullWidth
                      variant="standard"
                      onChange={e => {
                        setValue('superVisorSign', e.target.value);
                      }}

                    />
                  }
                />
              </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            </>) : (<>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                  Therapist
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name="therapistsId"
                  {...register("therapistsId")}
                  control={control}
                  render={({ field }) =>
                    <ReactSelect
                      {...field}
                      fullWidth
                      isDisabled={UserType === "Therapist"}
                      value={TherapistList.filter(function (options) {
                        console.log('TherapistID id in filed ', TherapistID)
                        return (
                          options.value == TherapistID
                        );
                      })}
                      onChange={e => {
                        setTherapistID(e.value)
                        setValue("therapistsId", e.value);
                        settherapistError(false)

                      }}
                      options={TherapistList}


                    />

                  }
                />
                {therapistError && <p style={{ color: 'red' }}>Please Select Therapist</p>}
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Leave Start Date
                </Typography>
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Leave End Date
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name="leaveStartDate"
                  {...register("leaveStartDate")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type="date"
                      size="small"

                      {...field}
                      value={leaveStartDate ? leaveStartDate : ''}
                      onChange={e => {
                        const selectedDate = e.target.value;
                        setValue('leaveStartDate', selectedDate);
                        setleaveStartDate(selectedDate);
                      }}
                      fullWidth
                      error={errors.leaveStartDate}
                    />}
                />
                <p style={{ color: 'red', fontSize: '14px' }}>{errors.leaveStartDate?.message}</p>
              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name="leaveEndDate"
                  {...register("leaveEndDate")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type="date"
                      size="small"

                      {...field}
                      value={leaveEndDate}
                      onChange={e => {
                        const selectedDate = e.target.value;
                        setValue('leaveEndDate', selectedDate);
                        setleaveEndDate(selectedDate);
                      }}
                      fullWidth
                      error={errors.leaveEndDate}
                    />}
                />
                <p style={{ color: 'red', fontSize: '14px' }}>{errors.leaveEndDate?.message}</p>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={11.5} sm={11.5} xl={11.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Purpose for leave
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name='leavePurpose'
                  {...register('leavePurpose')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='Enter Leave Purpose'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '70px', // set a fixed height here
                        //   overflow: 'scroll'
                      }}
                      {...field}
                      fullWidth
                      error={errors.leavePurpose}

                    />
                  )}
                />
                <p style={{ color: 'red', fontSize: '14px' }}>{errors.leavePurpose?.message}</p>

              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={11.5} sm={11.5} xl={11.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Additional Information
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={11} sm={11} xl={11}>
                <Controller
                  name='additionalInfo'
                  {...register('additionalInfo')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='Enter Additional Info'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '70px', // set a fixed height here
                        //   overflow: 'scroll'
                      }}
                      {...field}
                      fullWidth
                    />
                  )}
                />
              </Grid>


              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Supervisee Signature
                </Typography>

              </Grid>
              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                  Supervisor Signature
                </Typography>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name="superViseeSign"
                  control={control}
                  {...register("superViseeSign")}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Supervisee Signature..."
                      {...field}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleSignInSupervisee}
                              disabled={UserType == 'Supervisor'} // Disable button for Therapist
                            >
                              <SignInIcon style={{ color: '#356F60' }} />

                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                      onChange={e => {
                        setValue('superViseeSign', e.target.value);
                      }}

                    />
                  }
                />
                {therapistSignError && <p style={{ color: 'red' }}> Supervisee Sign required</p>}
              </Grid>

              <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                  name="supervisorSign"
                  control={control}
                  {...register("supervisorSign")}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Supervisor Signature..."
                      {...field}
                      fullWidth
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleSignInSupervisor}
                              disabled={UserType == 'Therapist'} // Disable button for Therapist
                            >
                              <SignInIcon style={{ color: '#356F60' }} />

                            </IconButton>
                          </InputAdornment>
                        ),
                      }}

                      onChange={e => {
                        setValue('supervisorSign', e.target.value);
                      }}

                    />
                  }
                />
                {SupervisorSignError && <p style={{ color: 'red' }}> Supervisor Sign required</p>}

              </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            </>)}

            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>


            {UserType == 'Therapist' ? (<>
              {approve == true ?
                (<>
                  <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                  <Grid item xs={3.6} sm={3.6} xl={3.6} style={{ marginTop: '10px' }} >
                    <Button
                      className="Button"
                      // onClick={handleSubmit(onSubmitagenda)}
                      disabled={approve ? true : submitting}

                      fullWidth
                      style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                      type="submit"
                    >

                      Approved
                      <TaskAltIcon
                        style={{
                          color: approve == true ? "green" : "red",
                          // cursor: "not-allowed",
                        }}
                      >

                      </TaskAltIcon>
                    </Button>
                  </Grid>

                  {/* <Grid item xs={3.6} sm={3.6} xl={3.6} style={{ marginTop: '10px' }} >
                  <Button
                    className="Button"
                    // onClick={handleSubmit(onSubmitreject)}
                    // disabled={submitting}
                    fullWidth
                    style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                  // type="submit"
                  >
                    Reject
                  </Button>
                </Grid> */}

                </>) : (<>

                  {LeaveRequestid > 0 ? (<>
                    <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                    <Grid item xs={3.6} sm={3.6} xl={3.6} style={{ marginTop: '10px' }} >
                      <Button
                        className="Button"
                        onClick={handleSubmit(OnSubmitAprroval)}
                        disabled={submitting}
                        fullWidth
                        style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '12px', fontWeight: 'bold' }}
                        type="submit"
                      >
                        Send For Approval
                        <TaskAltIcon
                          style={{
                            color: "red",
                            // cursor: "not-allowed",
                          }}
                        >

                        </TaskAltIcon>
                      </Button>
                    </Grid></>) : (<>
                      {/* <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid> */}

                      <Grid item xs={3.6} sm={3.6} xl={3.6} style={{ marginTop: '10px' }} >
                        <Button
                          className="Button"
                          onClick={handleSubmit(onSubmit)}
                          disabled={submitting}
                          fullWidth
                          style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '12px', fontWeight: 'bold' }}
                          type="submit"
                        >
                          Save

                        </Button>
                      </Grid>
                    </>)}



                </>)}


            </>) : (
              <>
                {LeaveRequestid > 0 ? (
                  <>
                    <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
                    <Grid item xs={3.6} sm={3.6} xl={3.6} style={{ marginTop: '10px' }} >
                      <Button
                        className="Button"
                        onClick={handleSubmit(onSubmit)}
                        disabled={approve ? true : submitting}
                        fullWidth
                        style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                        type="submit"
                      >
                        {approve == true ? 'Approved' : 'Approve'}

                        <TaskAltIcon
                          style={{
                            color: approve == true ? 'green' : 'red',

                            // cursor: "not-allowed",
                          }}
                        >

                        </TaskAltIcon>
                      </Button>
                    </Grid>

                    {UserType == 'Admin' || UserType == 'Supervisor' ? (
                      <>

                        {approve == true ? '' : (


                          <>
                            <Grid item xs={3.6} sm={3.6} xl={3.6} style={{ marginTop: '10px' }} >
                              <Button
                                className="Button"
                                onClick={handleSubmit(onSubmitreject)}
                                disabled={submitting}
                                fullWidth
                                style={{
                                  borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold',
                                  display: approve == true ? 'none' : 'block',
                                }}
                                type="submit"
                              >
                                Reject
                                <TaskAltIcon style={{
                                  color: 'red',
                                }} />
                              </Button>
                            </Grid>
                          </>
                        )
                        }

                      </>
                    ) : ''}


                  </>
                ) : (<>
                  <>


                    <Grid item xs={2.2} sm={2.2} xl={2.2}></Grid>

                    <Grid item xs={3.6} sm={3.6} xl={3.6} style={{ marginTop: '10px' }} >
                      <Button
                        className="Button"
                        onClick={handleSubmit(onSubmit)}
                        disabled={approve ? true : submitting}
                        fullWidth
                        style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Grid>


                  </>


                </>)}
              </>


            )}

            <Grid item xs={3.6} sm={3.6} xl={3.6} style={{ marginTop: '10px' }}>
              <Button
                className="Button"
                onClick={handleCancel}
                disabled={submitting}
                fullWidth
                style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}

              >

                Cancel
              </Button>
            </Grid>


            {/* <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid> */}

          </Grid>



        </Box>
      </Grid>
    </React.Fragment>
  );
};
export default LeaveRegistration;
