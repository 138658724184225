import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// Login Redux States
import { LOGIN_USER, API_ERROR, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import axios from "axios";
//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";
import md5 from 'md5'
import Swal from 'sweetalert2';
const fireBaseBackend = getFirebaseBackend();
function* loginUser({ payload: { user, history } }) {
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      var postData = {
        "userName": user.userName,
        "password": md5(user.password)
      }
      axios
        .post(`${process.env.REACT_APP_API_URL}/Users/login`, postData, null)
        .then(response => {
          if (response && response.token) {
            // If the response contains a token, make the GET request
            console.log("login Success +++:  ");
            localStorage.setItem("authUser", JSON.stringify(response));
            localStorage.setItem("Token", response.token);
            localStorage.setItem("User_Type", response.userType);
            localStorage.setItem("default_paractice", response.defaultPracticeId);
            localStorage.setItem("UserName", response.userName);
            localStorage.setItem("Emailaddress", response.email);
            localStorage.setItem("userid", response.id);
            localStorage.setItem("roomName", response.roomName);
            localStorage.setItem("SupervisorID", response.supervisorId);
            console.log('roomName11',response.roomName)
            put(loginSuccess(response));
            console.log(response.defaultPracticeId, 'defpracid');
            // Make the GET request to getUserPractices endpoint
            // axios
            //   .get(`${process.env.REACT_APP_API_URL}/UserPractices/getUserPractices?userName=${user.UserName}`, {
            //     headers: {
            //       Authorization: `Bearer ${localStorage.getItem("Token")}`,
            //     },
            //   })
            //   .then((response) => {
            //     const practiceName = response[0].practiceName;
            //     localStorage.setItem("Practice_Name", practiceName);
            //     console.log("Response ", practiceName);
            //   })
            //   .catch((error) => {             
            //   });
            // Redirect to dashboard
            history("/Scheduler");
          } else {
            // Handle the case where the response does not contain a token
            Swal.fire({
              title: 'User Name or Password incorrect. Please enter valid credentials.',
              icon: 'error',
              confirmButtonColor: '#0BB197',
              confirmButtonText: 'Okay',
            });
            console.log("login failed..... ");
            put(apiError(API_ERROR, 'login failed'));
          }
        })
        .catch(error => {
          // Handle error from login endpoint
        });
    } else {
      // Handle the case where REACT_APP_DEFAULTAUTH is not "fake"
    }
  } catch (error) {
    yield put(apiError(error));
  }
}
function* logoutUser() {
  try {
    localStorage.removeItem("authUser");
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(LOGOUT_USER, response));
    } else {
      yield put(logoutUserSuccess(LOGOUT_USER, true));
    }
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}
// function* socialLogin({ payload: { data, history, type } }) {
//   try {
//     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//       const fireBaseBackend = getFirebaseBackend();
//       const response = yield call(fireBaseBackend.socialLoginUser, data, type);
//       localStorage.setItem("authUser", JSON.stringify(response));
//       yield put(loginSuccess(response));
//     } else {
//       const response = yield call(postSocialLogin, data);
//       localStorage.setItem("authUser", JSON.stringify(response));
//       yield put(loginSuccess(response));
//     }
//     history("/dashboard");
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }
function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  // yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}
export default authSaga;
