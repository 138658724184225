import { useState, useEffect, useRef } from "react";
import React from "react";
import ReactSelect from "react-select";
import axios from "axios";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import md5 from "md5";
import Moment from 'moment'
import InputMask from 'react-input-mask';


import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Select
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from "react-alert";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from '@mui/material/Paper'

import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Multiselect from "multiselect-react-dropdown";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from '@mui/material/Modal';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FileUploader } from "react-drag-drop-files";
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserRegistration = props => {
  const fields = [
    "city",
    "contactNo",
    "userName",
    "createdBy",
    "createdDate",
    "dateJoined",
    "defaultFacilityId",
    "defaultPracticeId",
    "dob",
    "email",
    "fullName",
    "userName",
    "gender",
    "id",
    "provider_id",
    "isActive",
    "isStaff",
    "isSuperUser",
    "isVerified",
    "lastLogin",
    "password",
    "profilePhoto",
    "state",
    "street",
    "token",
    "updatedBy",
    "updatedDate",
    "userType",
    "zipCode",
    "provider",
    "address",
    "PracticeId",
    "photoUrl",
    "userslinked",
    "linkedUsersId",
    "resetPassword",
    "age",
    "ethnicity",
    "race",
    "licenseType",
    "licenseNumber",
    "licenseApprovalDate",
    "licenseExpirationDate",
    "dateIDIssued",
    "dateIDExpiration",
    "preferredGender",
    "insuranceCarrierName",
    "policyNumber",
    "insurancePolicyDate",
    "insuranceEndDate"
  ];
  const defValues = {
    city: "",
    userName: "",
    contactNo: "",
    createdBy: "",
    userName: "",
    createdDate: "",
    dateJoined: "",
    defaultFacilityId: "",
    defaultPracticeId: "",
    dob: "",
    userslinked: "",
    email: "",
    fullName: "",
    gender: "",
    id: 0,
    provider_id: "",
    isActive: "",
    isStaff: "",
    isSuperUser: "",
    isVerified: "",
    lastLogin: "",
    password: "",
    profilePhoto: "",
    state: "",
    street: "",
    token: "",
    updatedBy: "",
    updatedDate: "",
    userType: "",
    zipCode: "",
    provider: "",
    address: "",
    PracticeId: "",
    photoUrl: "",
    linkedUsersId: "",
    resetPassword: "",
    age: "",
    ethnicity: "",
    race: "",
    licenseType: "",
    licenseNumber: "",
    licenseApprovalDate: "",
    licenseExpirationDate: "",
    dateIDIssued: "",
    dateIDExpiration: "",
    preferredGender: "",
    insuranceCarrierName: "",
    policyNumber: "",
    insurancePolicyDate: "",
    insuranceEndDate: ""

  };
  const modalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #377562',
    boxShadow: 24,
    overflow: 'hidden',
    p: 4,
    borderRadius: '8px',
    overflow: 'auto',

  };
  const [Emergency, setEmergency] = React.useState(false);
  const handleOpenEmergency = () => setEmergency(true);
  const handleCloseEmergency = () => setEmergency(false);
  const [isLoading, setisLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [userName, setuserName] = useState("");
  console.log('userName', userName)
  const [userType, setuserType] = useState("");
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [errorReceived, seterrorReceived] = useState("");
  const [zipDownloaded, setzipDownloaded] = useState(false);
  const [fetchedData, setfetchedData] = useState(false);
  const [userPractice, setUserPractice] = useState(0);
  const [userFacility, setUserFacility] = useState(0);
  const [rows, setRows] = useState(null);
  const [rows1, setRows1] = useState(null);
  const [Dob, setDob] = React.useState('')
  const [licenseApprovalDate, setlicenseApprovalDate] = React.useState(Moment().format('YYYY-MM-DD'))
  const [licenseExpirationDate, setlicenseExpirationDate] = React.useState(Moment().format('YYYY-MM-DD'))
  const [dateidissued, setDateidissued] = React.useState(Moment().format('YYYY-MM-DD'))
  const [dateIDExpiration, setDateIDExpiration] = React.useState(Moment().format('YYYY-MM-DD'))
  const [insurancePolicyDate, setInsurancePolicyDate] = React.useState(Moment().format('YYYY-MM-DD'))
  const [insuranceEndDate, setInsuranceEndDate] = React.useState(Moment().format('YYYY-MM-DD'))





  const [providerType, setProviderType] = React.useState("");
  const [userChildList, setUsersChildList] = React.useState([]);
  const [usersList, setUsersList] = React.useState([]);
  const [selectedUserIds, setselectedUserIds] = React.useState([]);
  const [selectUserError, setselectUserError] = useState(false);
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const [cellPhone, setcellPhone] = React.useState()

  const location = useLocation();
  const _userId = props.uid;
  // const PracticeId = location.state.PracticeId;

  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  // const Emailaddress = localStorage.getItem("Emailaddress")
  const [Emailaddress, setEmailaddress] = useState("");
  const [practicesList, setpracticesList] = useState("");
  console.log("practicesList", practicesList);
  const [practiceslistid, setpracticeslistid] = useState([]);
  //   const practiceslistid = [];
  console.log("practiceslistid", practiceslistid);
  const facilitiesList = [];
  const [practiceIdError, setPracticeIDError] = useState(false);
  const [providerIdError, setProviderIdError] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);
  const [practiceID, setPracticeID] = useState("");
  console.log("aaaa", practiceID);
  const [userProvider, setUserProvider] = useState(0);
  const [providers, setProviders] = useState([]);
  const [defaultPracticeError, setDefaultPracticeError] = useState(false);
  const [defaultPracticeErrorId, setDefaultPracticeErrorId] = useState("");
  const validationSchema = Yup.object({
    // fullName: Yup.string().required("Full Name is required"),
    userType: Yup.string().required("User Type is required"),
    userName: Yup.string().required("User Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(3, "Password must be at least 3 characters"),

    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password")],
      "Passwords does not match"
    ),
    // defaultPracticeId: Yup.array().required("Practice Name is required")
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#377562',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }

  const [Emergencycontantid, setEmergencycontantid] = useState("");

  const [Emergencycontant, setEmergencycontant] = useState([]);
  console.log("testconat", Emergencycontant)
  const [Namecontant, setNamecontant] = useState("");
  const [Relationshipcontant, setRelationshipcontant] = useState("");
  const [Phonecontant, setPhonecontant] = useState("");
  const [Emailcontant, setEmailcontant] = useState("");
  const [Addresscontant, setAddresscontant] = useState("");


  // const onchangeEmergency = () => {
  //   setEmergencycontant(prevEmergencycontant => [...prevEmergencycontant, { Namecontant }]);
  // }

  const [nameError, setNameError] = useState('');
  const [relationshipError, setRelationshipError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [EmergencyErorr, setEEmergencyErorr] = useState(false);

  const onchangeEmergency = async () => {
    let isValid = true;

    if (!Namecontant.trim()) {
      setNameError('Full Name Required');
      isValid = false;
    } else {
      setNameError('');
    }

    if (!Relationshipcontant.trim()) {
      setRelationshipError('Relationship Required');
      isValid = false;
    } else {
      setRelationshipError('');
    }

    if (!Phonecontant.trim()) {
      setPhoneError('Phone Number Required');
      isValid = false;
    } else {
      setPhoneError('');
    }

    if (!Addresscontant.trim()) {
      setAddressError('Address Required');
      isValid = false;
    } else {
      setAddressError('');
    }

    if (!isValid) return;
    Emergencycontant.push({
      "id": 0,
      "userId": _userId,
      "fullname": Namecontant,
      "relationship": Relationshipcontant,
      "phone": Phonecontant,
      "email": Emailcontant,
      "address": Addresscontant,
    });
    handleCloseEmergency();
  }
  const onSubmit = data => {
    var selectedIds = [];
    var unSelectedIds = [];
    selectedUserIds.forEach(field => {
      if (typeof field.value != undefined && field.value != null)
        selectedIds.push(field.value);
      else selectedIds.push(field.id);
    });
    let postData = data;
    console.log("checck selct postData___", postData);
    postData.linkedUsersId = selectedIds;
    postData.emergencyContact = Emergencycontant;
    console.log("checck selct id________", postData.linkedUsersId);
    postData.unlinkedUsersId = [];
    postData.userType = userType ? userType : "";
    if (isPasswordChange) {
      console.log('ispassword change  my aaya ha k nahiii ')
      postData.password = md5(postData.password);
      postData.confirmPassword = md5(postData.confirmPassword);
    } else {
      postData.password = postData.password;
      postData.confirmPassword = postData.confirmPassword;
    }
    if (postData.userType == "" || postData.userType == null) {
      setselectUserError(true);
      return;
    }

    if (!Emergencycontant || Emergencycontant.length === 0) {
      setEEmergencyErorr(true)
      return;
    } 

    setSubmitting(true);
    seterrorReceived("");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Users/userRegistration`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);
          toast.success('Record saved successfully', {
            position: "bottom-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",

            onClose: () => {
              props.handleClose()

            }

          });
          // alert.success("Record saved successfully.", {
          //   type: "success",
          //   onClose: () => {
          //     navigate("/User");
          //   }
          // });
        } else if (response.length > 0) {
          seterrorReceived(response.data);
          setSubmitting(false);
          return;
        }
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
  const [userEmail, setUserEmail] = useState("");



  useEffect(() => {
    if(_userId){
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUserById?id=${_userId}`, {
        headers
      })
      .then(response => {
        console.log("response.data: ", response);
        setuserType(response["userType"]);
        setcellPhone(response.contactNo)
        if (response.emergencyContacts != null) {
          setEmergencycontant(response.emergencyContacts)

        }
        // setEmergencycontantid(response.id)
        fields.forEach(field => {
          setValue(field, response[field]);
          var child = [];
          response.userslinked.forEach((res) => {
            child.push({
              name: res.fullName,
              value: res.id,
            });
          });
          setUsersChildList(child);
          setselectedUserIds(child);
          setProviderType(response.userType);
          setProviderType(response.userType);
       setDob(Moment(response.dob).format('YYYY-MM-DD'));
          var dateYMD = Moment(response.licenseApprovalDate).format('YYYY-MM-DD')
          setlicenseApprovalDate(dateYMD)
          var dateYMD = Moment(response.dateIDIssued).format('YYYY-MM-DD')
          setDateidissued(dateYMD)
          var dateYMD = Moment(response.dateIDExpiration).format('YYYY-MM-DD')
          setDateIDExpiration(dateYMD)
          var dateYMD = Moment(response.insurancePolicyDate).format('YYYY-MM-DD')
          setInsurancePolicyDate(dateYMD)
          var dateYMD = Moment(response.insuranceEndDate).format('YYYY-MM-DD')
          setInsuranceEndDate(dateYMD)

          var dateYMD = Moment(response.licenseExpirationDate).format('YYYY-MM-DD')
          setlicenseExpirationDate(dateYMD)

          setEmailaddress(response.email)
          if (field === "userName") {
            setuserName(response[field]);
          }
          if (field === "password") {
            setValue("confirmPassword", response[field]);
          }
        });
        setPracticeID(response.defaultPracticeId);

        setDefaultPracticeErrorId(response.defaultPracticeId);
      })
      .catch(error => { });
    }
  }, []);


  const [images, setImages] = React.useState(null);
  ///Delete Image
  const deleteimg = () => {
    setImages(null);
    setValue("photoUrl", "");
  };
  ///Upload Image
  const onImageChange = e => {
    setImages(URL.createObjectURL(e.target.files[0]));
    const file = e.target.files[0];
    convertBase64(file);
    // console.log("64 image",base64)
    console.log(
      "Kdr Sy aya ha Console",
      URL.createObjectURL(e.target.files[0])
    );
  };
  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    }).then(users => {
      setValue("base64", users);
    });
  };
  const Input = styled("input")({
    display: "none"
  });

  const [open, setOpen] = React.useState(false);

  function handleCancel() {
    navigate("/User");
  }
  const [tabValue, settabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    settabValue(newValue);
    setValue("defaultPracticeId", "");
    setValue("provider_id", "");
    setPracticeID("");
    setProviderIdError(false);
    setPracticeIDError(false);
    setDefaultPracticeError(false);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    height: 400,
    bgcolor: 'white',
    border: '2px solid #377562',
    boxShadow: 24,
    overflow: 'hidden',
    p: 1,
    borderRadius: '8px',
    overflow: 'auto',
    /* WebKit (Chrome, Safari) */
    '&::-webkit-scrollbar': {
      width: 8, /* Width of vertical scrollbar */
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#377562',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    /* Adjust the width for the horizontal scrollbar */
    '&::-webkit-scrollbar-thumb:horizontal': {
      width: 4, /* Width of horizontal scrollbar */
    },
    /* For Firefox */
    '&*': {
      scrollbarWidth: 'thin',
    },
  }
  const [docError, setdocError] = useState(false)
  const [filename, setfilename] = useState([])
  const [base64Data, setBase64Data] = useState([])
  const [totalProgress, setTotalProgress] = React.useState(0)
  const [savedFiles, setSavedFiles] = React.useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const inputRef = useRef(null)
  // document Uplaod
  const fileToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  ///////////////////////////////////
  //Single and multiple files
  const handleChangeforfile = async files => {
    const newFilenames = []
    const newBase64Data = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const base64 = await fileToBase64(file)
      newFilenames.push(file.name)
      newBase64Data.push(base64)
    }

    setfilename(newFilenames)
    setBase64Data(newBase64Data)
    setdocError(false)
  }


  return (
    <React.Fragment>
      <Grid container>
        <Modal
          open={Emergency}
          onClose={handleCloseEmergency}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalstyle}>
            <Grid
              container
              style={{ backgroundColor: '#356F60', position: 'sticky' }}
            >
              <Grid container spacing={1} style={{ background: '#356F60', display: 'flex', borderRadius: '8px', padding: '3px' }}>
                <Grid item xs={11} sm={11} xl={11} >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      // textAlign: 'left',
                      // marginTop:'-10px', 
                      color: '#fff',
                      textDecoration: 'underline',
                      fontSize: '1rem'

                    }}
                  >
                    Emergency Contacts
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} >

                  <CancelOutlinedIcon
                    onClick={handleCloseEmergency}
                    fontSize="large"
                    color="red"
                    style={{
                      color: 'red',
                      paddingRight: '5px',
                      cursor: 'pointer',
                      float: 'right'
                      // marginLeft: 'auto',
                      // marginRight: '1.5rem'
                    }}
                  ></CancelOutlinedIcon>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              {/* <Grid item xs={10} sm={10} xl={10} style={{marginTop:"-20px"}}>
          <Typography style={{ fontSize: '14px',fontWeight:'bold', color:'black'}}>
                      Emergency Contacts
                  </Typography>
          </Grid>
          <Grid item xs={2} sm={2} xl={2} style={{marginTop:"-20px"}}>
          <CancelOutlinedIcon
                        onClick={handleCloseEmergency}
                        fontSize="medium"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
          </Grid> */}
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  NAME
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Relationship
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="FullName"
                  // {...register("userName")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="please enter full name"
                      {...field}
                      fullWidth
                      value={Namecontant}
                      onChange={ev => {
                        console.log('ee:', ev.target.value)
                        setNamecontant(ev.target.value)

                      }
                      }

                    />}
                />
                {nameError && (
                  <Typography style={{ color: 'red', fontSize: '12px' }}>
                    {nameError}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="Relationship"
                  // {...register("userName")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="please enter relationship"
                      {...field}
                      fullWidth
                      value={Relationshipcontant}
                      onChange={ev => {
                        console.log('ee:', ev.target.value)
                        setRelationshipcontant(ev.target.value)

                      }
                      }

                    />}
                />
                {relationshipError && (
                  <Typography style={{ color: 'red', fontSize: '12px' }}>
                    {relationshipError}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Phone
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Email
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <InputMask
                  mask='+1 999-999-9999'
                  disabled={false}
                  value={Phonecontant}
                  style={{ height: '30px' }}
                  onChange={ev => {
                    console.log('ee:', ev.target.value)
                    setPhonecontant(ev.target.value)

                  }
                  }
                >
                  {() => <TextField id='outlined-basic' fullWidth size='small' placeholder='enter cell phone' />}
                </InputMask>
                {/* <Controller
                        name="Phone"
                       
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter Phone No"
                            {...field}
                            fullWidth
                            value={Phonecontant}
                            onChange={ev => {
                              console.log('ee:', ev.target.value)
                             setPhonecontant(ev.target.value)
                              
                              }
                            }
                            
                          />}
                      /> */}
                {phoneError && (
                  <Typography style={{ color: 'red', fontSize: '12px' }}>
                    {phoneError}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Controller
                  name="Email"
                  // {...register("userName")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type="email"
                      size="small"
                      placeholder="please enter email"
                      {...field}
                      fullWidth
                      value={Emailcontant}
                      onChange={ev => {
                        console.log('ee:', ev.target.value)
                        setEmailcontant(ev.target.value)

                      }
                      }

                    />}
                />

              </Grid>
              <Grid item xs={12} sm={12} xl={12}>
                <Typography style={{ fontSize: '14px' }}>
                  Address
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} xl={12}>
                <Controller
                  name="Address"
                  // {...register("userName")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="please enter address"
                      {...field}
                      fullWidth
                      value={Addresscontant}
                      onChange={ev => {
                        console.log('ee:', ev.target.value)
                        setAddresscontant(ev.target.value)

                      }
                      }

                    />}
                />
                {addressError && (
                  <Typography style={{ color: 'red', fontSize: '12px' }}>
                    {addressError}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12} xl={12} style={{ marginTop: '10px' }}>
                <Button
                  className="Button"
                  onClick={onchangeEmergency}
                  // disabled={submitting}
                  fullWidth
                  style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold', float: 'right' }}
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
            </Grid>

          </Box>
        </Modal>
        {/* <Box sx={style}> */}

        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              User Name
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              Email
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name="userName"
              {...register("userName")}
              control={control}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="please enter full name"
                  {...field}
                  fullWidth
                  error={errors.userName}
                />}
            />
            <p style={{ color: 'red', fontSize: '14px' }}>{errors.userName?.message}</p>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name="email"
              {...register("email")}
              control={control}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="please enter email"
                  {...field}
                  fullWidth
                  error={errors.email}
                />}
            />
            <p style={{ color: 'red', fontSize: '14px' }}>{errors.email?.message}</p>
          </Grid>

          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              Password
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              Confirm Password
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name="password"
              {...register("password")}
              control={control}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="please enter password"
                  {...field}
                  type="password"
                  onChange={(e) => {
                    setValue("password", e.target.value);
                  }}
                  fullWidth
                  error={errors.password}
                />}
            />

            <p style={{ color: 'red', fontSize: '14px' }}>{errors.password?.message}</p>
          </Grid>

          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name="confirmPassword"
              {...register("confirmPassword")}
              control={control}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="please enter confirm password"
                  {...field}
                  fullWidth
                  type="password"
                  onChange={(e) => {
                    setIsPasswordChange(true);
                    setValue("confirmPassword", e.target.value);
                    console.log("setIsPasswordChange my kya value ha ", isPasswordChange)
                  }}
                  error={errors.confirmPassword}
                />
              }
            />
            <p style={{ color: 'red', fontSize: '14px' }}>{errors.confirmPassword?.message}</p>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              DOB
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              Age
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name="dob"
              control={control}
              render={({ field }) => (
                <TextField
                  type='date'
                  size='small'
                  fullWidth
                  value={Dob} // Use field.value directly
                  placeholder='Enter dob'
                  onChange={(e) => {
                    setValue('dob', e.target.value)
                    setDob(e.target.value)
                  }}
                />
              )}
            />

          </Grid>

          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name="age"
              {...register("age")}
              control={control}
              render={({ field }) =>
                <TextField
                  type='text'
                  size='small'
                  fullWidth
                  {...field}
                  placeholder='enter age'
                />
              }
            />

          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              Gender
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              Preferred Gender
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name='gender'
              margin='dense'
              {...register('gender')}
              control={control}
              render={({ field }) => (
                <ReactSelect
                  style={{ marginTop: 0, marginBottom: 0 }}

                  {...field}
                  options={[
                    { value: 'Male', label: 'Male' },
                    { value: 'Female', label: 'Female' },
                    { value: 'Other', label: 'Other' }
                  ]}
                  value={{ label: getValues('gender') }}
                  onChange={value => setValue('gender', value.label)}
                  size='small'
                />
              )}
            />

          </Grid>

          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name='preferredGender'
              margin='dense'
              {...register('preferredGender')}
              control={control}
              render={({ field }) => (
                <ReactSelect
                  style={{ marginTop: 0, marginBottom: 0 }}

                  {...field}
                  options={[
                    { value: 'Male', label: 'Male' },
                    { value: 'Female', label: 'Female' },
                    { value: 'Other', label: 'Other' }
                  ]}
                  value={{ label: getValues('preferredGender') }}
                  onChange={value => setValue('preferredGender', value.label)}
                  size='small'
                />
              )}
            />

          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              Ethnicity
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              Race
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name="ethnicity"
              {...register("ethnicity")}
              control={control}
              render={({ field }) =>
                <TextField
                  type='text'
                  size='small'
                  fullWidth
                  {...field}
                  placeholder='enter Ethnicity'
                />
              }
            />

          </Grid>

          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name="race"
              {...register("race")}
              control={control}
              render={({ field }) =>
                <TextField
                  type='text'
                  size='small'
                  fullWidth
                  {...field}
                  placeholder='enter race'
                />
              }
            />

          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              Address
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              Phone No
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name="address"
              {...register("address")}
              control={control}
              render={({ field }) =>
                <TextField
                  type='text'
                  size='small'
                  fullWidth
                  {...field}
                  placeholder='enter address'
                />
              }
            />

          </Grid>

          <Grid item xs={6} sm={6} xl={6}>
            <InputMask
              mask='+1 999-999-9999'
              disabled={false}
              value={cellPhone}
              style={{ height: '30px' }}
              onChange={e => {
                setValue('contactNo', e.target.value)
                setcellPhone(e.target.value)
              }}
            >
              {() => <TextField id='outlined-basic' fullWidth size='small' placeholder='enter cell phone' />}
            </InputMask>

          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              License Type
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              License Number
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name="licenseType"
              {...register("licenseType")}
              control={control}
              render={({ field }) =>
                <TextField
                  type='text'
                  size='small'
                  fullWidth
                  {...field}
                  placeholder='enter license type'
                />
              }
            />

          </Grid>

          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name="licenseNumber"
              {...register("licenseNumber")}
              control={control}
              render={({ field }) =>
                <TextField
                  type='text'
                  size='small'
                  fullWidth
                  {...field}
                  placeholder='enter license number'
                />
              }
            />

          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              License Approval Date
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              License Expiration  Date
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name="licenseApprovalDate"
              // {...register("dob")}
              control={control}
              render={({ field }) =>
                <TextField
                  type='date'
                  size='small'
                  fullWidth
                  {...field}
                  value={licenseApprovalDate ? licenseApprovalDate : ''}
                  onChange={e => {
                    setValue('licenseApprovalDate', e.target.value)
                    setlicenseApprovalDate(e.target.value)
                  }}

                />
              }
            />

          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name="licenseExpirationDate"
              // {...register("dob")}
              control={control}
              render={({ field }) =>
                <TextField
                  type='date'
                  size='small'
                  fullWidth
                  {...field}
                  value={licenseExpirationDate ? licenseExpirationDate : ''}
                  onChange={e => {
                    setValue('licenseExpirationDate', e.target.value)
                    setlicenseExpirationDate(e.target.value)
                  }}

                />
              }
            />

          </Grid>

          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              Date ID Issued
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px' }}>
              Date ID Expiration
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name="dateIDIssued"
              // {...register("dob")}
              control={control}
              render={({ field }) =>
                <TextField
                  type='date'
                  size='small'
                  fullWidth
                  {...field}
                  value={dateidissued ? dateidissued : ''}
                  onChange={e => {
                    setValue('dateIDIssued', e.target.value)
                    setDateidissued(e.target.value)
                  }}

                />
              }
            />

          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name="dateIDExpiration"
              // {...register("dob")}
              control={control}
              render={({ field }) =>
                <TextField
                  type='date'
                  size='small'
                  fullWidth
                  {...field}
                  value={dateIDExpiration ? dateIDExpiration : ''}
                  onChange={e => {
                    setValue('dateIDExpiration', e.target.value)
                    setDateIDExpiration(e.target.value)
                  }}

                />
              }
            />

          </Grid>
          {/* <Grid item xs={12} sm={12} xl={4} style={{marginTop:'5px'}}>
                <Typography style={{ fontSize: '14px',fontWeight:'bold',color:'black' }}>
                Supervisee Form of Identification (DL, Passport or State ID)
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px',color:'black' }}>
                   Front of ID
                  </Typography>
               
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px',color:'black' }}>
                  Back of ID
                  </Typography>
               
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
            <FileUploader multiple={true}  handleChange={handleChangeforfile}  name='file' />
               
               
                </Grid> */}
          <Grid item xs={12} sm={12} xl={4}>
            <Typography style={{ fontSize: '14px' }}>
              Type
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} xl={4}>
            <Controller
              name="userType"
              margin="dense"
              {...register("userType")}
              control={control}
              render={({ field }) =>
                <ReactSelect
                  style={{ marginTop: 0, marginBottom: 0 }}
                  {...field}
                  size="small"
                  options={[
                    { value: "Admin", label: "Admin" },
                    { value: "Supervisor", label: "Supervisor" },
                    { value: "Therapist", label: "Therapist" },
                  ]}
                  value={{ label: getValues("userType") }}
                  onChange={value => {
                    setValue("userType", value.value);
                    setuserType(value.value);
                    setProviderType(value.label);
                  }}
                  error={errors.userType}

                />}
            />
            <p style={{ color: 'red', fontSize: '14px' }}>{errors.userType?.message}</p>

          </Grid>

          {/* {providerType == 'Supervisor'?(<>
                  <Grid item xs={12} sm={12} xl={4}>
                  <Typography
                        color="black"
                        variant="caption"
                        fontSize="15px"
                      >
                        Users / Team
                      </Typography>
                     
                      <Multiselect
                        style={{ zIndex: 999999 }}
                        displayValue="name"
                        isObject={true}
                        onRemove={function unlinkProviderToSupervisor(
                          list,
                          item
                        ) {
                          setselectedUserIds(list);
                          console.log("Removee", list);
                        }}
                        onSelect={function linkProviderToSupervisor(
                          list,
                          item
                        ) {
                          setselectedUserIds(list);
                          console.log("mlisttttttttttt", list);
                        }}
                        options={usersList}
                        selectedValues={userChildList}
                        showCheckbox
                      />
                </Grid>
            
                
                </>):(<></>)} */}
          <Grid item xs={10} sm={10} xl={10} style={{ marginTop: '5px' }}>
            <Typography style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
              Emergency Contacts
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} xl={2}>
            <AddCircleOutlineRoundedIcon
              onClick={handleOpenEmergency}
              fontSize="large"
              color="green"
              style={{
                color: 'green',
                paddingRight: '5px',
                cursor: 'pointer',
                float: 'right'
                // marginLeft: 'auto',
                // marginRight: '1.5rem'
              }}
            ></AddCircleOutlineRoundedIcon>
            {/* <Button onClick={handleOpenEmergency}>Open modal</Button> */}
          </Grid>
          {EmergencyErorr && (
                  <Typography style={{ color: 'red', fontSize: '12px',marginLeft:'5px'}}>
                   Please Select Emergency Contacts
                  </Typography>
                )}
          <Grid item xs={12} sm={12} xl={12}>
            {Emergencycontant != null && Emergencycontant.length > 0
              ? Emergencycontant.map((row, index) => {
                return (
                  <Paper
                    variant='outlined'
                    square
                    style={{
                      backgroundColor: '#F0F6F8',
                      borderRadius: '15px',
                      marginTop: '5px',
                      borderColor: '#377562',
                      maxWidth: '100%',
                      padding: '.9rem',
                      marginBottom: '1rem',
                      marginLeft: '0 .2rem'
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} xl={6}>
                        <Typography style={{ fontSize: '14px' }}>
                          <b>Name:</b>  {row.fullname}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} xl={6}>
                        <Typography style={{ fontSize: '14px', color: 'black' }}>
                          <b>Relationship:</b> {row.relationship}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} xl={6}>
                        <Typography style={{ fontSize: '14px' }}>
                          <b>Phone:</b>  {row.phone}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} xl={6}>
                        <Typography style={{ fontSize: '14px', color: 'black' }}>
                          <b>Email:</b> {row.email}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} xl={12}>
                        <Typography style={{ fontSize: '14px', color: 'black' }}>
                          <b>Address:</b> {row.address}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                )

              })
              : 'No Record Found'}
          </Grid>
          <Grid item xs={12} sm={12} xl={12}>
            <Typography style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>
              Professional Liability Insurance
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} xl={4} style={{ marginTop: '5px' }}>
            <Typography style={{ fontSize: '14px', }}>
              Insurance Carrier Name:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} xl={8}>
            <Controller
              name="insuranceCarrierName"
              {...register("insuranceCarrierName")}
              control={control}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="please enter insurance carrier name"
                  {...field}
                  fullWidth

                />}
            />
          </Grid>
          <Grid item xs={4} sm={4} xl={4} style={{ marginTop: '5px' }}>
            <Typography style={{ fontSize: '14px', }}>
              Policy Number:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} xl={8}>
            <Controller
              name="policyNumber"
              {...register("policyNumber")}
              control={control}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="please enter policy number"
                  {...field}
                  fullWidth

                />}
            />
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px', }}>
              Insurance Policy Date
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Typography style={{ fontSize: '14px', }}>
              Insurance End Date
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name="insurancePolicyDate"
              // {...register("dob")}
              control={control}
              render={({ field }) =>
                <TextField
                  type='date'
                  size='small'
                  fullWidth
                  {...field}
                  value={insurancePolicyDate ? insurancePolicyDate : ''}
                  onChange={e => {
                    setValue('insurancePolicyDate', e.target.value)
                    setInsurancePolicyDate(e.target.value)
                  }}

                />
              }
            />

          </Grid>
          <Grid item xs={6} sm={6} xl={6}>
            <Controller
              name="insuranceEndDate"
              // {...register("dob")}
              control={control}
              render={({ field }) =>
                <TextField
                  type='date'
                  size='small'
                  fullWidth
                  {...field}
                  value={insuranceEndDate ? insuranceEndDate : ''}
                  onChange={e => {
                    setValue('insuranceEndDate', e.target.value)
                    setInsuranceEndDate(e.target.value)
                  }}

                />
              }
            />

          </Grid>





          <Grid item xs={6} sm={6} xl={6} style={{ marginTop: '10px' }}>
            <Button
              className="Button"
              onClick={handleSubmit(onSubmit)}
              disabled={submitting}
              fullWidth
              style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
              type="submit"
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} xl={6} style={{ marginTop: '10px' }}>
            <Button
              className="Button"
              onClick={props.handleClose}
              disabled={submitting}
              fullWidth
              style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}

            >

              Cancel
            </Button>
          </Grid>
        </Grid>



        {/* </Box> */}
      </Grid>
    </React.Fragment>
  );
};
export default UserRegistration;
